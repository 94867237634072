import { useState, useCallback, useEffect } from "react";
import * as StoriesAPI from "api/stories";
import { useToastContext } from "contexts/ToastContext";

/**
 * A custom hook that serves as a wrapper for a set of functions to interact with a single story.
 *
 * @param {Object} initialStory - The initial state of the story.
 * @returns {Object} An object containing the story data, loading state, error message,
 *                  and functions to update, delete, and create a new story.
 */
const useStory = (initialStory = null) => {
  const [story, setStory] = useState(initialStory);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(initialStory ? "idle" : "loading");
  const [statusMessage, setStatusMessage] = useState("");
  const { addToast } = useToastContext();

  // Initialize with default values if no initial story provided
  useEffect(() => {
    if (!initialStory) {
      setStory({
        title: "",
        body: "",
        tags: [],
        anonymized: true,
      });
      setStatus("idle");
      setStatusMessage("");
      return;
    }
  }, [initialStory]);

  const createStory = useCallback(async (newData) => {
    try {
      setStatus("loading");
      setStatusMessage("Creating story...");
      const createdStory = await StoriesAPI.submitStory(newData);
      setStory(createdStory);
      setStatus("success");
      setStatusMessage("Story created successfully!");
      return createdStory;
    } catch (err) {
      setError(err.message || "An error occurred");
      setStatus("error");
      setStatusMessage("Failed to create story.");
      console.error("Error creating story:", err);
      throw err;
    }
  }, []);

  const updateStory = useCallback(async (id, updatedData) => {
    try {
      setStatus("loading");
      setStatusMessage("Updating story...");
      const updatedStory = await StoriesAPI.updateStory(id, updatedData);
      setStory(updatedStory);
      setStatus("success");
      setStatusMessage("Story updated successfully!");
      return updatedStory;
    } catch (err) {
      setError(err.message || "An error occurred");
      setStatus("error");
      setStatusMessage("Failed to update story.");
      console.error("Error updating story:", err);
      throw err;
    }
  }, []);

  const deleteStory = useCallback(
    async (id) => {
      try {
        setStatus("loading");
        setStatusMessage("Deleting story...");
        await StoriesAPI.deleteStory(id);
        setStatus("success");
        setStatusMessage("Story deleted successfully!");
        addToast("Story deleted successfully", "success");
        return id;
      } catch (err) {
        setError(err.message || "An error occurred");
        setStatus("error");
        setStatusMessage("Failed to delete story.");
        addToast(err.message || "Error deleting story", "error");
        console.error("Error deleting story:", err);
        throw err;
      }
    },
    [addToast]
  );

  return {
    story,
    error,
    status,
    statusMessage,
    createStory,
    updateStory,
    deleteStory,
  };
};

export default useStory;
