// packages/client/src/App.js
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import styles from "./App.module.css";

import { Navbar } from "components";
import Views from "pages/Views";
import ToastNotifs from "components/ToastNotif/ToastNotifs";
import { ToastContext } from "contexts/ToastContext";
import CallDialog from "components/CallDialog/CallDialog";
import WebViewRedirect from "components/WebViewRedirect/WebViewRedirect";

const App = () => {
  const location = useLocation();
  const hideNavbarRoutes = [
    "/register",
    "/stories/new",
    "/stories/:storyId/edit",
  ];
  const showNavbar = !hideNavbarRoutes.some((route) => {
    // Use exact match for simple paths
    if (!route.includes(":")) {
      return location.pathname === route;
    }
    // Use pattern matching for dynamic routes
    return new RegExp(`^${route.replace(/:\w+/g, "[^/]+")}$`).test(
      location.pathname
    );
  });

  const { toastList, removeToast } = useContext(ToastContext);

  return (
    <div id="app" className={styles.app}>
      <WebViewRedirect />
      {showNavbar && <Navbar />}
      <main className={styles.main}>
        <Views />
      </main>
      <ToastNotifs toastList={toastList} removeToast={removeToast} />
      <CallDialog />
    </div>
  );
};

export default App;
