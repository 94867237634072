import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Write.module.css";
import { RiBookOpenLine, RiQuillPenLine } from "react-icons/ri";
import { BsLightbulb } from "react-icons/bs";
import { MdOutlineTimeline } from "react-icons/md";
import useMediaQuery from "hooks/useMediaQuery";
import cn from "classnames";

/**
 * AddToolbar component for the Write page
 * Displays buttons to add a new life experience or write a story
 */
const AddToolbar = ({ onAddLifeExperience }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("sm");

  return (
    <div
      className={cn(styles.optionsContainer, {
        [styles.mobileOptionsContainer]: isMobile,
      })}
    >
      <button
        className={cn(styles.optionCard, styles.compactOption)}
        onClick={onAddLifeExperience}
        aria-label="Add Life Experience"
      >
        <MdOutlineTimeline className={styles.icon} />
        <h2>Add Life Experience</h2>
        <p>
          A brief summary of a significant milestone or event you've
          experienced.
          <span className={styles.descriptionDetail}>
            Examples: overcoming an illness, facing a major life change, or
            dealing with a specific challenge.
          </span>
        </p>
      </button>

      <button
        className={cn(styles.optionCard, styles.compactOption)}
        onClick={() => navigate("/stories/new")}
        aria-label="Write a Story"
      >
        <RiQuillPenLine className={styles.icon} />
        <h2>Write a Story</h2>
        <p>
          A detailed narrative that explores your experiences in depth.
          <span className={styles.descriptionDetail}>
            Often develops from a life experience, adding personal insights,
            emotions, and the journey in greater detail.
          </span>
        </p>
      </button>
    </div>
  );
};

export default AddToolbar;
