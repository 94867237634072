import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Write.module.css";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import LifeExperienceModal from "components/LifeExperienceModal/LifeExperienceModal";
import { useUserContext } from "contexts/UserContext";
import { useWriteLifeExperiences } from "./useWriteLifeExperiences";
import { useWriteStories } from "./useWriteStories";
import { AddToolbar, ContentTabs } from "./components";
import useMediaQuery from "hooks/useMediaQuery";
import cn from "classnames";

const Write = () => {
  const location = useLocation();
  const [isLifeExperienceModalOpen, setIsLifeExperienceModalOpen] = useState(
    location.state?.openLifeExperience || false
  );
  const initialStory = location.state?.initialStory || "";
  const { userData } = useUserContext();
  const isMobile = useMediaQuery("sm");

  // Use our custom hooks for life experiences and stories
  const {
    lifeExperiences,
    loading: lifeExpLoading,
    createLifeExperience,
    updateLifeExperience,
    deleteLifeExperience,
    setLifeExperiences,
  } = useWriteLifeExperiences();

  const { stories, loading: storiesLoading, deleteStory } = useWriteStories();

  // Clear the location state after using it
  useEffect(() => {
    if (location.state?.openLifeExperience) {
      window.history.replaceState({}, document.title);
    }
  }, []);

  const handleCreateLifeExperience = async (formData) => {
    try {
      await createLifeExperience(formData);
      setIsLifeExperienceModalOpen(false);
    } catch (error) {
      // Error handling is done in the hook
    }
  };

  const handleLifeExperienceUpdate = async (updatedExperience) => {
    try {
      await updateLifeExperience(updatedExperience._id, updatedExperience);
    } catch (error) {
      // Error handling is done in the hook
    }
  };

  const handleLifeExperienceDelete = async (deletedId, options = {}) => {
    try {
      if (!deletedId) {
        console.error("Attempted to delete life experience without ID");
        return;
      }

      // Skip API call if it was already completed by the card component
      if (options.apiCallCompleted) {
        console.log(
          `Skipping API call for deletion of ${deletedId} - already completed`
        );
        // We still need to update our local state
        setLifeExperiences((prev) =>
          prev.filter((exp) => exp._id !== deletedId)
        );
        return;
      }

      // Otherwise proceed with normal deletion flow
      await deleteLifeExperience(deletedId);
    } catch (error) {
      // Error handling is done in the hook
    }
  };

  const handleStoryDelete = async (deletedId, options = {}) => {
    try {
      if (!deletedId) {
        console.error("Attempted to delete story without ID");
        return;
      }

      await deleteStory(deletedId, options);
    } catch (error) {
      // Error handling is done in the hook
    }
  };

  const isLoading =
    lifeExpLoading &&
    storiesLoading &&
    lifeExperiences.length === 0 &&
    stories.length === 0;

  return (
    <div
      className={cn(styles.container, {
        [styles.mobileContainer]: isMobile,
      })}
    >
      <h1 className={styles.pageTitle}>Share Your Journey</h1>

      <AddToolbar
        onAddLifeExperience={() => setIsLifeExperienceModalOpen(true)}
      />

      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      ) : (
        <ContentTabs
          lifeExperiences={lifeExperiences}
          stories={stories}
          lifeExpLoading={lifeExpLoading}
          storiesLoading={storiesLoading}
          userData={userData}
          onLifeExperienceUpdate={handleLifeExperienceUpdate}
          onLifeExperienceDelete={handleLifeExperienceDelete}
          onStoryDelete={handleStoryDelete}
        />
      )}

      <LifeExperienceModal
        isOpen={isLifeExperienceModalOpen}
        onClose={() => setIsLifeExperienceModalOpen(false)}
        mode="new"
        onCreate={handleCreateLifeExperience}
        initialData={{ story: initialStory }}
      />
    </div>
  );
};

export default Write;
