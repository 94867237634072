// packages/client/src/pages/Views.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";

import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import Landing from "./Landing/Landing";
import Lobby from "./Lobby/Lobby";
import Login from "./Login/Login";
import Register from "./Register/Register";
import Help from "./Help/Help";
import ContactUs from "./ContactUs/ContactUs";

import PageWrapper from "components/PageWrapper/PageWrapper";
import Profile from "./Profile/Profile";
import Chats from "./Chats/Chats";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./TermsOfService/TermsOfService";

import NewStory from "./NewStory/NewStory";
import EditStory from "./EditStory/EditStory";

import StoryDetail from "./StoryDetail/StoryDetail";
import NotFoundPage from "./NotFoundPage/NotFoundPage";

import VerifyEmail from "./EmailVerification/VerifyEmail";

import AdminDashboard from "./AdminDashboard/AdminDashboard";

// Comment out the Feedback import
// import Feedback from "./Feedback/Feedback";
import Rules from "./Rules/Rules";
import Reactivate from "./Reactivate/Reactivate";
import FindOthers from "./FindOthers/FindOthers";
import Write from "./Write/Write";
import Settings from "./Settings/Settings";

import ProfileRedirect from "./ProfileRedirect/ProfileRedirect";

const Views = () => {
  return (
    <Routes>
      {/* ===== Public Routes ===== */}
      <Route
        path="/login"
        element={
          <PageWrapper ticker footer>
            <Login />
          </PageWrapper>
        }
      />
      <Route
        path="/terms"
        element={
          <PageWrapper ticker footer>
            <TermsOfService />
          </PageWrapper>
        }
      />
      <Route
        path="/privacy"
        element={
          <PageWrapper ticker footer>
            <PrivacyPolicy />
          </PageWrapper>
        }
      />
      <Route
        path="/rules"
        element={
          <PageWrapper ticker footer>
            <Rules />
          </PageWrapper>
        }
      />
      <Route
        path="/contact"
        element={
          <PageWrapper ticker footer>
            <ContactUs />
          </PageWrapper>
        }
      />

      <Route
        path="/verify-email"
        element={
          <PageWrapper ticker footer>
            <VerifyEmail />
          </PageWrapper>
        }
      />

      <Route
        path="/"
        element={
          <PageWrapper ticker footer>
            <Landing />
          </PageWrapper>
        }
      />

      <Route path="/profile" element={<ProfileRedirect />} />

      {/* ===== Admin Routes ===== */}
      <Route element={<ProtectedRoutes loggedInOnly isAdmin redirectTo="/" />}>
        <Route path="/admin/*" element={<AdminDashboard />} />
      </Route>

      {/* ===== Authentication Routes ===== */}
      <Route element={<ProtectedRoutes loggedInOnly />}>
        <Route path="/register" element={<Register />} />
        <Route
          path="/reactivate"
          element={
            <PageWrapper>
              <Reactivate />
            </PageWrapper>
          }
        />
        <Route
          path="/settings"
          element={
            <PageWrapper>
              <Settings />
            </PageWrapper>
          }
        />
      </Route>

      {/* ===== Basic User Routes (require email verification) ===== */}
      <Route
        element={
          <ProtectedRoutes loggedInOnly registeredOnly emailVerifiedOnly />
        }
      >
        {" "}
        <Route path="/user/:username" element={<Profile />}>
          <Route path="experiences" element={<Profile />} />
          <Route path="stories" element={<Profile />} />
          <Route path="quotes" element={<Profile />} />
          <Route path="reviews" element={<Profile />} />
        </Route>
        <Route
          path="/help"
          element={
            <PageWrapper>
              <Help />
            </PageWrapper>
          }
        />
      </Route>

      {/* ===== Content Routes (Require Active Account) ===== */}
      <Route
        element={
          <ProtectedRoutes
            loggedInOnly
            registeredOnly
            emailVerifiedOnly
            activeOnly
          />
        }
      >
        <Route
          path="/lobby"
          element={
            <PageWrapper>
              <Lobby />
            </PageWrapper>
          }
        />
        <Route
          path="/find"
          element={
            <PageWrapper>
              <FindOthers />
            </PageWrapper>
          }
        />
        <Route
          path="/chats"
          element={
            <PageWrapper>
              <Chats />
            </PageWrapper>
          }
        />
        <Route path="/stories/new" element={<NewStory />} />
        <Route
          path="/stories/:storyId"
          element={
            <PageWrapper>
              <StoryDetail />
            </PageWrapper>
          }
        />
        <Route path="/stories/:storyId/edit" element={<EditStory />} />
        <Route
          path="/write"
          element={
            <PageWrapper>
              <Write />
            </PageWrapper>
          }
        />
      </Route>

      {/* ===== Catch-all Route ===== */}
      <Route
        path="*"
        element={
          <PageWrapper>
            <NotFoundPage />
          </PageWrapper>
        }
      />
    </Routes>
  );
};

export default Views;
