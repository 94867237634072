import React, { useState, useEffect } from "react";
import Button from "components/Button/Button";
import { deleteUserById, checkAvailability, changeUsername } from "api/users";
import { fetchUserData } from "api/auth";
import styles from "./Settings.module.css";
import Modal from "components/Modal/Modal";
import ModalHeader from "components/Modal/ModalHeader";
import ModalBody from "components/Modal/ModalBody";
import ModalButtons from "components/Modal/ModalButtons";
import InputWrapper from "components/InputWrapper/InputWrapper";
import ToggleButton from "components/Button/ToggleButton/ToggleButton";
import { HiOutlinePencil, HiOutlineBell, HiOutlineTrash } from "react-icons/hi";
import { useToastContext } from "contexts/ToastContext";

const Settings = () => {
  const [profile, setProfile] = useState({});
  const [username, setUsername] = useState("");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(undefined);
  const [confirmDeleteText, setConfirmDeleteText] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUsernameModalOpen, setIsUsernameModalOpen] = useState(false);
  const [lastUsernameChange, setLastUsernameChange] = useState(null);
  const [daysToChangeUsername, setDaysToChangeUsername] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [changeUsernameStatus, setChangeUsernameStatus] = useState("initial");
  const [emailNotifications, setEmailNotifications] = useState(false);
  const { addToast } = useToastContext();

  const handleAccountDelete = async (profileUsername, confirmText) => {
    if (profileUsername !== confirmText) {
      throw new Error("Username does not match");
    }
    try {
      await deleteUserById(profile._id);
      addToast({
        type: "success",
        title: "Account Deleted",
        message: "Your account has been successfully deleted.",
      });
      // Redirect to logout or home page
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    } catch (error) {
      addToast({
        type: "error",
        title: "Error",
        message: "Failed to delete account. Please try again.",
      });
    }
  };

  const handleCheckUsernameAvailability = async (username, profile) => {
    const response = await checkAvailability(username);

    setIsUsernameAvailable(response.available);

    if (username === profile.username) {
      setIsUsernameAvailable(undefined);
    }
  };

  const addTwoWeeks = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 14);
    return newDate;
  };

  const daysFromNow = (date) => {
    const now = new Date();
    const targetDate = new Date(date);
    const timeDifference = targetDate - now;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  };

  const handleChangeUsername = async (userId, username) => {
    try {
      const response = await changeUsername(userId, { username: username });
      addToast({
        type: "success",
        title: "Username Changed",
        message: "Your username has been successfully updated.",
      });
      return response;
    } catch (error) {
      addToast({
        type: "error",
        title: "Error",
        message:
          error.message || "Failed to change username. Please try again.",
      });
      throw error;
    }
  };

  const handleApplyChanges = async () => {
    if (isUsernameAvailable) {
      try {
        setChangeUsernameStatus("loading");
        const response = await handleChangeUsername(profile._id, username);
        setChangeUsernameStatus("success");

        // Wait a moment to show success state before redirecting
        setTimeout(() => {
          window.location.href = `/settings`;
        }, 1000);
      } catch (error) {
        setChangeUsernameStatus("initial");
      }
    }
  };

  const handleEmailNotificationsToggle = () => {
    const newValue = !emailNotifications;
    setEmailNotifications(newValue);

    // Add toast notification
    addToast({
      type: "success",
      title: "Settings Updated",
      message: `Email notifications ${newValue ? "enabled" : "disabled"}.`,
    });

    // TODO: API call to update user preferences
  };

  useEffect(() => {
    if (!isDeleteModalOpen) {
      setConfirmDeleteText("");
    }
  }, [isDeleteModalOpen]);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const data = await fetchUserData();
        setProfile(data);
        setUsername(data.username);
        setLastUsernameChange(data.lastUsernameChange);
        setIsAdmin(data.role === "admin");
        setEmailNotifications(data.settings?.notifications?.email ?? false);
      } catch (error) {
        addToast({
          type: "error",
          title: "Error",
          message: "Failed to load profile data.",
        });
      }
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    if (lastUsernameChange) {
      const dateChange = new Date(lastUsernameChange);
      const newDate = addTwoWeeks(dateChange);
      const days = daysFromNow(newDate);
      setDaysToChangeUsername(days > 0 ? days : 0);
    }
  }, [lastUsernameChange]);

  const validateUsername = (username) => {
    if (!username || username.trim() === "") {
      return "Username cannot be empty";
    }
    if (username.length < 3) {
      return "Username must be at least 3 characters long";
    }
    if (username.length > 30) {
      return "Username must be less than 30 characters";
    }
    if (!/^[a-zA-Z0-9_-]+$/.test(username)) {
      return "Username can only contain letters, numbers, underscores, and hyphens";
    }
    return "";
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);

    // Clear previous errors
    setUsernameError("");
    setIsUsernameAvailable(undefined);

    // Validate username format
    const error = validateUsername(newUsername);
    if (error) {
      setUsernameError(error);
      return;
    }

    // Check availability if validation passes
    setIsCheckingUsername(true);
    handleCheckUsernameAvailability(newUsername, profile).finally(() =>
      setIsCheckingUsername(false)
    );
  };

  const isUsernameChangeDisabled = () => {
    if (isAdmin) return false; // Admins can always change username
    return daysToChangeUsername > 0;
  };

  return (
    <div className={styles.settingsContainer}>
      <h1 className={styles.settingsTitle}>Account Settings</h1>

      <div className={styles.settingSection}>
        <div className={styles.sectionHeader}>
          <HiOutlinePencil className={styles.sectionIcon} />
          <h2>Username</h2>
        </div>
        <div className={styles.sectionContent}>
          <div className={styles.currentUsername}>
            <span className={styles.label}>Current username:</span>
            <span className={styles.value}>{profile.username}</span>
          </div>

          {daysToChangeUsername > 0 && !isAdmin && (
            <div className={styles.cooldownMessage}>
              You can change your username again in {daysToChangeUsername} days.
            </div>
          )}

          <Button
            className={styles.actionButton}
            color="orange"
            size="medium"
            shape="rounded"
            onClick={() => setIsUsernameModalOpen(true)}
            disabled={isUsernameChangeDisabled()}
          >
            Change Username
          </Button>
        </div>
      </div>

      <div className={styles.settingSection}>
        <div className={styles.sectionHeader}>
          <HiOutlineBell className={styles.sectionIcon} />
          <h2>Notifications</h2>
        </div>
        <div className={styles.sectionContent}>
          <div className={styles.settingRow}>
            <div className={styles.settingLabel}>
              <span>Email notifications</span>
              <p className={styles.settingHint}>
                Receive email notifications about new matches and messages
              </p>
            </div>
            <ToggleButton
              isOn={emailNotifications}
              handleToggle={handleEmailNotificationsToggle}
            />
          </div>
        </div>
      </div>

      <div className={`${styles.settingSection} ${styles.dangerSection}`}>
        <div className={styles.sectionHeader}>
          <HiOutlineTrash className={styles.sectionIcon} />
          <h2>Deactivate Account</h2>
        </div>
        <div className={styles.sectionContent}>
          <p className={styles.dangerText}>
            Deactivating your account will make it inaccessible for 30 days,
            after which it will be permanently deleted. You can reactivate it by
            logging in during this period.
          </p>
          <Button
            className={styles.deleteButton}
            color="error"
            size="medium"
            shape="rounded"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Deactivate Account
          </Button>
        </div>
      </div>

      {/* Username Change Modal */}
      <Modal
        isOpen={isUsernameModalOpen}
        onClose={() => setIsUsernameModalOpen(false)}
        showCloseButton={true}
      >
        <ModalHeader>Change Username</ModalHeader>
        <ModalBody>
          <div className={styles.modalContent}>
            <p className={styles.modalText}>
              Your username is how other users will identify you on the
              platform.
            </p>

            <InputWrapper
              label="New Username"
              valid={!usernameError && isUsernameAvailable}
              helperText={
                usernameError ||
                (!isAdmin && daysToChangeUsername > 0
                  ? `${daysToChangeUsername} days remaining until the username can be changed again.`
                  : isAdmin
                  ? "As an admin, you can change your username at any time."
                  : isUsernameAvailable !== undefined
                  ? isUsernameAvailable
                    ? "Username available"
                    : "Username already taken"
                  : "Changing your username can have unintended side effects.")
              }
              isLoading={isCheckingUsername}
            >
              <input
                className={styles.modalInput}
                value={username}
                onChange={handleUsernameChange}
                disabled={isUsernameChangeDisabled()}
                placeholder="Enter new username"
              />
            </InputWrapper>
          </div>
        </ModalBody>
        <ModalButtons>
          <Button
            color="orange"
            size="medium"
            shape="square"
            onClick={() => handleApplyChanges()}
            disabled={
              isUsernameChangeDisabled() ||
              !isUsernameAvailable ||
              !!usernameError ||
              isCheckingUsername ||
              changeUsernameStatus === "success" ||
              changeUsernameStatus === "loading"
            }
            fetchState={changeUsernameStatus}
          >
            {changeUsernameStatus === "success"
              ? "Username changed!"
              : "Save Changes"}
          </Button>
          <Button
            color="gray"
            size="medium"
            shape="square"
            onClick={() => setIsUsernameModalOpen(false)}
            disabled={changeUsernameStatus === "loading"}
          >
            Cancel
          </Button>
        </ModalButtons>
      </Modal>

      {/* Delete Account Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        showCloseButton={false}
      >
        <ModalHeader>Deactivate Account</ModalHeader>
        <ModalBody>
          <div className={styles.deleteModalContent}>
            <p className={styles.modalText}>
              This action will <strong>deactivate</strong> your account
              immediately. Your profile and data will no longer be visible or
              accessible.
            </p>
            <p className={styles.modalText}>
              Your account data will be kept for <strong>[30 days]</strong> to
              allow for recovery. After this period, it will be{" "}
              <strong>permanently purged</strong> from our servers. You can
              reactivate your account by logging in before then.
            </p>
            <p className={styles.modalText}>
              To confirm deactivation, please type your username{" "}
              <strong>{profile.username}</strong> below:
            </p>
            <input
              className={styles.deleteInputField}
              value={confirmDeleteText}
              onChange={(e) => setConfirmDeleteText(e.target.value)}
              placeholder={`Type "${profile.username}" to confirm`}
            />

            <div className={styles.deleteWarning}>
              <p>Upon deactivation, you will temporarily lose:</p>
              <ul>
                <li>Visibility of your profile and personal information</li>
                <li>Access to your stories and life experiences</li>
                <li>Access to your conversations</li>
                <li>Ability to use your reputation and scores</li>
              </ul>
              <p style={{ marginTop: "0.5rem", fontSize: "0.85rem" }}>
                These will be permanently deleted after the recovery period if
                you do not reactivate.
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalButtons>
          <Button
            color="error"
            size="medium"
            shape="square"
            onClick={() =>
              handleAccountDelete(profile.username, confirmDeleteText)
            }
            disabled={confirmDeleteText !== profile.username}
          >
            Deactivate Account
          </Button>
          <Button
            color="gray"
            size="medium"
            shape="square"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </Button>
        </ModalButtons>
      </Modal>
    </div>
  );
};

export default Settings;
