import React, { useState } from "react";
import {
  useFloating,
  useInteractions,
  useDismiss,
  useRole,
  FloatingOverlay,
  FloatingPortal,
  FloatingFocusManager,
} from "@floating-ui/react";
import { IoClose } from "react-icons/io5";
import styles from "./ReachOutModal.module.css";
import Button from "components/Button/Button";
import { useToastContext } from "contexts/ToastContext";
import * as REACHOUTS_API from "api/reachouts";
import * as typedefs from "../../typedefs";

/**
 * @typedef {typedefs.LifeExperienceData} LifeExperience
 * @typedef {typedefs.StoryData} Story
 */

/**
 * ReachOutModal component that allows users to send messages.
 * @param {Object} props - Component props.
 * @param {boolean} props.isOpen - Controls the visibility of the modal.
 * @param {function} props.onClose - Function to handle modal close.
 * @param {string} props.type - The type of data being referenced (e.g., "LifeExperience" or "Story").
 * @param {LifeExperience | Story} props.resource - The life experience or story data.
 */
const ReachOutModal = ({ isOpen, onClose, type, resource }) => {
  const { addToast } = useToastContext();
  const [message, setMessage] = useState("");
  const [fetchState, setFetchState] = useState("initial");
  const [response, setResponse] = useState(null);
  const [view, setView] = useState("form"); // 'form' | 'success'

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([dismiss, role]);

  // Validate type and resource
  const validTypes = ["LifeExperience", "Story"];
  if (!validTypes.includes(type)) {
    console.error(
      `Invalid type: ${type}. Expected 'LifeExperience' or 'Story'.`
    );
    return null;
  }

  if (
    !resource ||
    (type === "LifeExperience" && !resource.story) ||
    (type === "Story" && !resource.title)
  ) {
    console.error("Invalid resource provided.");
    return null;
  }

  const handleSendMessage = async () => {
    if (!message.trim()) {
      addToast("Please enter a message", "error");
      return;
    }

    setFetchState("loading");
    try {
      const response = await REACHOUTS_API.createReachout({
        resourceId: resource._id,
        resourceType: type,
        message,
      });

      setResponse(response);
      setFetchState("success");
      setView("success");
    } catch (error) {
      console.error("Failed to send message:", error);
      setFetchState("error");
      addToast(error.message || "Failed to send message", "error");
      setTimeout(() => {
        setFetchState("initial");
      }, 2000);
    }
  };

  const getChatLink = (response) => {
    if (!response) return "#";
    return `/chats?type=reachouts&id=${response._id}`;
  };

  if (!isOpen) return null;

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll className={styles.overlay}>
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={styles.dialog}
            {...getFloatingProps()}
          >
            {view === "form" ? (
              <>
                <div className={styles.header}>
                  <div className={styles.title}>Reach Out</div>
                  <button onClick={onClose} className={styles.closeButton}>
                    <IoClose />
                  </button>
                </div>

                <div className={styles.content}>
                  <div className={styles.resourcePreview}>
                    <h3 className={styles.resourceTitle}>
                      {type === "Story"
                        ? "About this story"
                        : "About this experience"}
                    </h3>
                    <p className={styles.resourceContent}>
                      {type === "LifeExperience"
                        ? resource.story
                        : resource.title}
                    </p>
                  </div>

                  <div className={styles.messageArea}>
                    <label className={styles.messageLabel}>Your message:</label>
                    <textarea
                      className={styles.messageInput}
                      placeholder="Type your message here..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      disabled={fetchState === "loading"}
                    />
                  </div>

                  <div className={styles.actions}>
                    <Button
                      onClick={onClose}
                      className={styles.cancelButton}
                      shape="square"
                      color="gray"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSendMessage}
                      fetchState={fetchState}
                      shape="square"
                      color="orange"
                      disabled={!message.trim() || fetchState === "loading"}
                    >
                      {fetchState === "loading" ? "Sending..." : "Send Message"}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className={styles.successView}>
                <div className={styles.successHeader}>
                  <div className={styles.checkmark}>✓</div>
                  <h3>Message Sent!</h3>
                </div>

                <p className={styles.successMessage}>
                  Your message has been successfully sent. The other user will
                  be notified about your reach out.
                </p>

                <div className={styles.successActions}>
                  <Button onClick={onClose} shape="square" color="gray">
                    Close
                  </Button>
                  <Button
                    shape="square"
                    color="success"
                    href={getChatLink(response)}
                  >
                    Go to Chat
                  </Button>
                </div>
              </div>
            )}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default ReachOutModal;
