import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getAccountStatus, reactivateAccount, confirmDeletion } from "api/auth";
import Button from "components/Button/Button";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useToastContext } from "contexts/ToastContext";
import styles from "./Reactivate.module.css";
import { useUserContext } from "contexts/UserContext";
import { formatDistanceToNow } from "date-fns";
import {
  FaUndoAlt,
  FaExclamationTriangle,
  FaTrashAlt,
  FaUserPlus,
} from "react-icons/fa";
import { HiOutlineEmojiSad } from "react-icons/hi";

const Reactivate = () => {
  const [accountStatus, setAccountStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reactivateState, setReactivateState] = useState("initial");
  const [confirmDeleteState, setConfirmDeleteState] = useState("initial");
  const navigate = useNavigate();
  const { addToast } = useToastContext();
  const { updateUser } = useUserContext();

  useEffect(() => {
    const getStatus = async () => {
      setIsLoading(true);
      try {
        const statusData = await getAccountStatus();
        console.log("[Reactivate.jsx] Received statusData:", statusData);

        // Only check that the user is in a deleted state - we'll handle
        // recoverable vs non-recoverable status in the render
        if (statusData?.status !== "deleted") {
          addToast({
            type: "info",
            title: "Invalid State",
            message: "This page is only for deleted accounts.",
          });
          navigate("/");
        } else {
          setAccountStatus(statusData);
        }
      } catch (error) {
        // Error from getAccountStatus is already handled by api/auth
        navigate("/login");
      } finally {
        setIsLoading(false);
      }
    };
    getStatus();
  }, [navigate, addToast]);

  const handleReactivate = async () => {
    setReactivateState("loading");
    try {
      const { message, user } = await reactivateAccount();
      setReactivateState("success");
      if (user) {
        updateUser(user);
      }
      addToast({
        type: "success",
        title: "Success",
        message: message || "Account reactivated successfully! Welcome back.",
      });
      setTimeout(() => navigate("/"), 1500);
    } catch (error) {
      setReactivateState("error");
    }
  };

  const handleConfirmDeletion = async () => {
    setConfirmDeleteState("loading");
    try {
      await confirmDeletion();
      setConfirmDeleteState("success");
      addToast({
        type: "success",
        title: "Account Deleted",
        message: "Your account has been permanently deleted.",
      });
      setTimeout(() => {
        window.location.href = "/"; // Force reload to clear state
      }, 1500);
    } catch (error) {
      setConfirmDeleteState("error");
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingSpinner size="large" />
      </div>
    );
  }

  if (!accountStatus) {
    return (
      <div className={styles.container}>
        <p className={styles.message}>Could not load account status.</p>
      </div>
    );
  }

  // Check if the account is recoverable
  const isRecoverable = accountStatus?.deletionDetails?.isRecoverable;

  // If not recoverable, show the expired account view
  if (!isRecoverable) {
    return (
      <div className={styles.container}>
        <HiOutlineEmojiSad className={styles.icon} />
        <h1 className={styles.title}>Account Not Recoverable</h1>
        <p className={styles.message}>
          Unfortunately, the recovery period for this account has passed, and it
          has been permanently deleted from our systems.
        </p>
        <p className={styles.message}>
          If you wish to use Stumble again, you will need to create a new
          account.
        </p>
        <Link to="/register" className={styles.registerButton}>
          <FaUserPlus className={styles.buttonIcon} />
          Create New Account
        </Link>
      </div>
    );
  }

  // Otherwise, show the recoverable account view
  const { recoveryDeadline } = accountStatus.deletionDetails;
  const deadlineDate = recoveryDeadline ? new Date(recoveryDeadline) : null;
  const timeLeft = deadlineDate
    ? formatDistanceToNow(deadlineDate, { addSuffix: true })
    : "N/A";

  return (
    <div className={styles.container}>
      <FaUndoAlt className={styles.icon} />
      <h1 className={styles.title}>Account Recovery</h1>
      <p className={styles.message}>
        Your account was scheduled for deletion but is still recoverable.
      </p>
      {deadlineDate && (
        <p className={styles.deadlineInfo}>
          Permanent deletion is scheduled {timeLeft} (on{" "}
          {deadlineDate.toLocaleDateString()}).
        </p>
      )}
      <p className={styles.message}>
        Choose to reactivate your account or confirm its immediate permanent
        deletion.
      </p>
      <div className={styles.warningBox}>
        <FaExclamationTriangle className={styles.warningIcon} />
        <p className={styles.warningText}>
          Permanent deletion cannot be undone.
        </p>
      </div>

      <div className={styles.buttonGroup}>
        <Button
          color="orange"
          size="large"
          shape="rounded"
          onClick={handleReactivate}
          disabled={
            reactivateState === "loading" ||
            confirmDeleteState === "loading" ||
            reactivateState === "success"
          }
          fetchState={reactivateState}
          icon={<FaUndoAlt />}
        >
          {reactivateState === "success"
            ? "Account Reactivated!"
            : "Reactivate Account"}
        </Button>
        <Button
          color="error"
          size="large"
          shape="rounded"
          onClick={handleConfirmDeletion}
          disabled={
            reactivateState === "loading" ||
            confirmDeleteState === "loading" ||
            confirmDeleteState === "success"
          }
          fetchState={confirmDeleteState}
          icon={<FaTrashAlt />}
        >
          {confirmDeleteState === "success"
            ? "Deletion Confirmed"
            : "Confirm Permanent Deletion"}
        </Button>
      </div>
    </div>
  );
};

export default Reactivate;
