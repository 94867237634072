import React from "react";
import {
  useFloating,
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";
import Button from "components/Button/Button";
import { IoClose } from "react-icons/io5";
import styles from "./DeleteConfirmationModal.module.css";

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  itemName,
  status,
  statusMessage,
}) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, { outsidePressEvent: "mousedown" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    role,
    dismiss,
  ]);

  if (!isOpen) return null;

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll className={styles.overlay}>
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={styles.modal}
            {...getFloatingProps()}
          >
            <div className={styles.header}>
              <span>Confirm Deletion</span>
              <button onClick={onClose} className={styles.closeButton}>
                <IoClose />
              </button>
            </div>
            <div className={styles.content}>
              <p>
                Are you sure you want to delete this {itemName}? This action
                cannot be undone.
              </p>
            </div>
            {status === "error" && (
              <div className={styles.errorMessage}>{statusMessage}</div>
            )}
            <div className={styles.actions}>
              <Button
                onClick={onClose}
                className={styles.cancelButton}
                shape="square"
                size="small"
                color="gray"
              >
                Cancel
              </Button>
              <Button
                onClick={onConfirm}
                color="error"
                className={styles.deleteButton}
                disabled={status === "loading"}
                shape="square"
                size="small"
              >
                {status === "loading" ? "Deleting..." : "Delete"}
              </Button>
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default DeleteConfirmationModal;
