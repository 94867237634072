import { useState, useCallback, useEffect } from "react";
import * as LifeExpAPI from "api/lifeExperiences";
import { useToastContext } from "contexts/ToastContext";

/**
 * A custom hook that serves as a wrapper for a set of functions to interact with a single life experience.
 *
 * @param {Object} initialLifeExperience - The initial state of the life experience.
 * @returns {Object} An object containing the life experience data, loading state, error message,
 *                  and functions to update, delete, and create a new life experience.
 */
const useLifeExperience = (initialLifeExperience = null) => {
  const [lifeExperience, setLifeExperience] = useState(initialLifeExperience);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(
    initialLifeExperience ? "idle" : "loading"
  );
  const [statusMessage, setStatusMessage] = useState("");
  const { addToast } = useToastContext();

  useEffect(() => {
    if (!initialLifeExperience) {
      setLifeExperience({
        tag: { _id: "", name: "" },
        story: "",
        date: { month: null, year: null },
        searchable: false,
        anonymized: true,
      });
      setStatus("idle");
      setStatusMessage("");
      return;
    }
  }, [initialLifeExperience]);

  const createLifeExperience = useCallback(async (newData) => {
    try {
      setStatus("loading");
      setStatusMessage("Creating life experience...");
      const createdLifeExp = await LifeExpAPI.createLifeExperience(newData);
      setLifeExperience(createdLifeExp);
      setStatus("success");
      setStatusMessage("Life experience created successfully!");
      return createdLifeExp;
    } catch (err) {
      setError(err.message);
      setStatus("error");
      setStatusMessage("Failed to create life experience.");
      console.error("Error creating life experience:", err);
      throw err;
    }
  }, []);

  const updateLifeExperience = async (id, updatedData) => {
    try {
      setStatus("loading");
      setStatusMessage("Updating life experience...");
      const updatedLifeExp = await LifeExpAPI.updateLifeExperience(
        id,
        updatedData
      );
      setLifeExperience(updatedLifeExp);
      setStatus("success");
      setStatusMessage("Life experience updated successfully!");
      return updatedLifeExp;
    } catch (err) {
      setError(err.message);
      setStatus("error");
      setStatusMessage("Failed to update life experience.");
      throw err;
    }
  };

  const deleteLifeExperience = async (id) => {
    try {
      setStatus("loading");
      setStatusMessage("Deleting life experience...");
      await LifeExpAPI.deleteLifeExperience(id);
      setStatus("success");
      setStatusMessage("Life experience deleted successfully!");
      addToast("Life experience deleted successfully", "success");
      return id;
    } catch (err) {
      setError(err.message);
      setStatus("error");
      setStatusMessage("Failed to delete life experience.");
      addToast(err.message || "Error deleting life experience", "error");
      throw err;
    }
  };

  return {
    lifeExperience,
    error,
    status,
    statusMessage,
    createLifeExperience,
    updateLifeExperience,
    deleteLifeExperience,
  };
};

export default useLifeExperience;
