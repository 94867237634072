import React, { useState } from "react";
import styles from "../Write.module.css";
import { RiQuillPenLine } from "react-icons/ri";
import { MdOutlineTimeline } from "react-icons/md";
import { FaLayerGroup } from "react-icons/fa";
import LifeExperienceCard from "components/LifeExperienceCard/LifeExperienceCard";
import StoryCard from "components/StoryCard/StoryCard";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import useMediaQuery from "hooks/useMediaQuery";
import cn from "classnames";

const TABS = {
  ALL: "all",
  LIFE_EXPERIENCES: "lifeExperiences",
  STORIES: "stories",
};

/**
 * ContentTabs component for displaying user content with tabs
 * Allows switching between all content, life experiences, and stories
 */
const ContentTabs = ({
  lifeExperiences,
  stories,
  lifeExpLoading,
  storiesLoading,
  userData,
  onLifeExperienceUpdate,
  onLifeExperienceDelete,
  onStoryDelete,
}) => {
  const [activeTab, setActiveTab] = useState(TABS.ALL);
  const isMobile = useMediaQuery("sm");

  const renderTabButton = (tabName, label, icon) => {
    const Icon = icon;
    return (
      <button
        className={cn(styles.tabButton, {
          [styles.activeTab]: activeTab === tabName,
          [styles.mobileTabButton]: isMobile,
        })}
        onClick={() => setActiveTab(tabName)}
        aria-selected={activeTab === tabName}
        role="tab"
      >
        <Icon className={styles.tabIcon} />
        <span>{label}</span>
      </button>
    );
  };

  const renderContent = () => {
    const isLoading =
      (activeTab === TABS.ALL && (lifeExpLoading || storiesLoading)) ||
      (activeTab === TABS.LIFE_EXPERIENCES && lifeExpLoading) ||
      (activeTab === TABS.STORIES && storiesLoading);

    if (isLoading) {
      return (
        <div className={styles.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    }

    // Determine which items to display based on active tab
    const showLifeExperiences =
      activeTab === TABS.ALL || activeTab === TABS.LIFE_EXPERIENCES;
    const showStories = activeTab === TABS.ALL || activeTab === TABS.STORIES;

    // Calculate if there are any items to show
    const hasLifeExperiences =
      showLifeExperiences && lifeExperiences.length > 0;
    const hasStories = showStories && stories.length > 0;
    const hasNoContent = !hasLifeExperiences && !hasStories;

    if (hasNoContent) {
      // Return different empty state based on active tab
      let EmptyIcon;
      let message;

      if (activeTab === TABS.ALL) {
        EmptyIcon = FaLayerGroup;
        message = "You haven't created any content yet";
      } else if (activeTab === TABS.LIFE_EXPERIENCES) {
        EmptyIcon = MdOutlineTimeline;
        message = "No life experiences yet";
      } else {
        EmptyIcon = RiQuillPenLine;
        message = "No stories yet";
      }

      return (
        <p className={styles.emptyMessage}>
          <EmptyIcon size={32} />
          {message}
        </p>
      );
    }

    // Combine and sort content for ALL tab
    let combinedContent = [];

    if (activeTab === TABS.ALL) {
      combinedContent = [
        ...lifeExperiences.map((item) => ({ ...item, type: "lifeExperience" })),
        ...stories.map((item) => ({ ...item, type: "story" })),
      ].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }

    return (
      <div className={styles.contentList}>
        {activeTab === TABS.ALL &&
          combinedContent.map((item) =>
            item.type === "lifeExperience" ? (
              <LifeExperienceCard
                key={`lifeexp-${item._id}`}
                lifeExpData={item}
                userData={userData}
                onUpdate={onLifeExperienceUpdate}
                onDelete={onLifeExperienceDelete}
              />
            ) : (
              <StoryCard
                key={`story-${item._id}`}
                story={item}
                userData={userData}
                onDelete={onStoryDelete}
              />
            )
          )}

        {activeTab === TABS.LIFE_EXPERIENCES &&
          lifeExperiences.map((item) => (
            <LifeExperienceCard
              key={`lifeexp-${item._id}`}
              lifeExpData={item}
              userData={userData}
              onUpdate={onLifeExperienceUpdate}
              onDelete={onLifeExperienceDelete}
            />
          ))}

        {activeTab === TABS.STORIES &&
          stories.map((item) => (
            <StoryCard
              key={`story-${item._id}`}
              story={item}
              userData={userData}
              onDelete={onStoryDelete}
            />
          ))}
      </div>
    );
  };

  return (
    <div className={styles.tabsContainer}>
      <div
        className={cn(styles.tabButtons, {
          [styles.mobileTabButtons]: isMobile,
        })}
      >
        {renderTabButton(TABS.ALL, "All", FaLayerGroup)}
        {renderTabButton(
          TABS.LIFE_EXPERIENCES,
          "Life Experiences",
          MdOutlineTimeline
        )}
        {renderTabButton(TABS.STORIES, "Stories", RiQuillPenLine)}
      </div>
      <div className={styles.tabContent}>{renderContent()}</div>
    </div>
  );
};

export default ContentTabs;
