import { useState, useEffect } from "react";
import * as StoriesAPI from "api/stories";
import { useToastContext } from "contexts/ToastContext";

export const useWriteStories = () => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToast } = useToastContext();

  // Fetch all stories
  const fetchStories = async () => {
    try {
      setLoading(true);
      const data = await StoriesAPI.fetchStories();
      // Sort by updatedAt in descending order (newest first)
      const sortedStories = data.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setStories(sortedStories);
      return sortedStories;
    } catch (err) {
      setError(err.message);
      addToast("Error loading stories", "error");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Create a new story - redirects to story editor
  const createStory = () => {
    // This would typically redirect to the story editor page
    return "/stories/new";
  };

  // Update a story - this is typically handled in the StoryEditor component
  const updateStory = async (id, updatedData) => {
    try {
      setLoading(true);
      const updatedStory = await StoriesAPI.updateStory(id, updatedData);
      setStories((prev) =>
        prev.map((story) => (story._id === id ? updatedStory : story))
      );
      addToast("Story updated successfully", "success");
      return updatedStory;
    } catch (err) {
      setError(err.message);
      addToast("Error updating story", "error");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Delete a story
  const deleteStory = async (id, options = {}) => {
    try {
      setLoading(true);

      // Skip API call if it was already completed by the StoryCard component
      if (options.apiCallCompleted) {
        console.log(
          `Skipping API call for deletion of ${id} - already completed`
        );
        // Just update our local state
        setStories((prev) => prev.filter((story) => story._id !== id));
        setLoading(false);
        return id;
      }

      // Otherwise proceed with normal deletion flow
      await StoriesAPI.deleteStory(id);
      setStories((prev) => prev.filter((story) => story._id !== id));
      addToast("Story deleted successfully", "success");
      return id;
    } catch (err) {
      setError(err.message);
      addToast("Error deleting story", "error");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Load stories on mount
  useEffect(() => {
    fetchStories();
  }, []);

  return {
    stories,
    loading,
    error,
    fetchStories,
    createStory,
    updateStory,
    deleteStory,
  };
};
