import React, { useState, useEffect } from "react";
import {
  BsThreeDotsVertical,
  BsArrowLeft,
  BsExclamationTriangle,
  BsEye,
} from "react-icons/bs";
import { FaUser, FaBook, FaComment, FaFlag, FaHistory } from "react-icons/fa";
import styles from "./UserDetail.module.css";
import Button from "components/Button/Button";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "components/ReusableTooltip/ReusableTooltip";
import { useAdmin } from "hooks/useAdmin";
import { formatDate } from "utils/dates";
import { useNavigate } from "react-router-dom";
import UserActionModal from "../../components/UserActionModal";
import { useToastContext } from "contexts/ToastContext";
import HardDeleteConfirmModal from "../../components/HardDeleteConfirmModal";

const REGISTRATION_STATUS_MAP = {
  pending: "pending",
  registered: "registered",
  emailVerified: "email verified",
  adminApproved: "admin approved",
};

const ErrorState = ({ message, onRetry }) => (
  <div className={styles.errorState}>
    <BsExclamationTriangle size={48} />
    <h3>Something went wrong</h3>
    <p>{message || "Failed to load user details"}</p>
    {onRetry && <Button onClick={onRetry}>Try Again</Button>}
  </div>
);

const UserDetail = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToast } = useToastContext();
  const {
    fetchUserDetail,
    userDetails,
    userContent,
    fetchUserContentItems,
    userReports,
    fetchUserReports,
    approveUserAccount,
    banUserAccount,
    suspendUserAccount,
    deleteUserAccount,
    restoreUserAccount,
    userAdminActions,
    fetchUserAdminActions,
    userActivityLog,
    userActivityPagination,
    fetchUserActivity,
  } = useAdmin();
  const [activeTab, setActiveTab] = useState("overview");
  const [contentLoading, setContentLoading] = useState(false);
  const [contentError, setContentError] = useState(null);
  const [contentPage, setContentPage] = useState(1);
  const [contentType, setContentType] = useState("all");
  const [reportsLoading, setReportsLoading] = useState(false);
  const [reportsError, setReportsError] = useState(null);
  const [reportsPage, setReportsPage] = useState(1);
  const navigate = useNavigate();
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [adminActionsLoading, setAdminActionsLoading] = useState(false);
  const [adminActionsError, setAdminActionsError] = useState(null);
  const [isHardDeleteModalOpen, setIsHardDeleteModalOpen] = useState(false);
  const [userActivityLoading, setUserActivityLoading] = useState(false);
  const [userActivityError, setUserActivityError] = useState(null);
  const [userActivityPage, setUserActivityPage] = useState(1);

  // Define the loadUser function that will be used for retrying
  const loadUser = async () => {
    try {
      setLoading(true);
      // Add logging

      const userData = await fetchUserDetail(userId);

      // Check if we got valid data back
      if (!userData) {
        setError(
          "User not found or you don't have permission to view this user"
        );
      } else {
        setError(null);
      }
    } catch (err) {
      console.error("Error loading user:", err);
      setError(err.message || "Failed to load user details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUser();
  }, [userId]);

  useEffect(() => {
    if (activeTab === "content" && userId) {
      loadUserContent();
    }
  }, [activeTab, userId, contentPage, contentType]);

  useEffect(() => {
    if (activeTab === "reports" && userId) {
      loadUserReports();
    }
  }, [activeTab, userId, reportsPage]);

  useEffect(() => {
    if (activeTab === "activity" && userId) {
      loadUserActivity();
    }
  }, [activeTab, userId, userActivityPage]);

  const loadUserContent = async () => {
    if (!userId) return;

    setContentLoading(true);
    setContentError(null);

    try {
      await fetchUserContentItems(userId, {
        contentType,
        page: contentPage,
        limit: 10,
        sort: "-createdAt",
      });
    } catch (error) {
      setContentError("Failed to load user content");
      console.error("Error loading user content:", error);
    } finally {
      setContentLoading(false);
    }
  };

  const loadUserReports = async () => {
    if (!userId) return;

    setReportsLoading(true);
    setReportsError(null);

    try {
      await fetchUserReports(userId, {
        page: reportsPage,
        limit: 10,
      });
    } catch (error) {
      setReportsError("Failed to load user reports");
      console.error("Error loading user reports:", error);
    } finally {
      setReportsLoading(false);
    }
  };

  const loadUserActivity = async () => {
    if (!userId) return;
    setUserActivityLoading(true);
    setUserActivityError(null);
    try {
      await fetchUserActivity(userId, { page: userActivityPage, limit: 20 });
    } catch (error) {
      setUserActivityError("Failed to load user activity");
      console.error("Error loading user activity:", error);
    } finally {
      setUserActivityLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/admin/users");
  };

  // Add this function to handle actions
  const handleAction = (actionType) => {
    setActionType(actionType);
    setIsActionModalOpen(true);
  };

  // Add this function to handle action submission
  const handleActionSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      let res;
      switch (actionType) {
        case "Approve":
          res = await approveUserAccount(data);
          break;
        case "Ban":
          res = await banUserAccount(data);
          break;
        case "Suspend":
          res = await suspendUserAccount(data);
          break;
        case "Delete":
          res = await deleteUserAccount(data);
          break;
        case "Restore":
          res = await restoreUserAccount(data);
          break;
        default:
          throw new Error("Invalid action type");
      }
      addToast(res.message, "success");
      loadUser(); // Refresh user data
    } catch (err) {
      addToast(
        `Failed to ${actionType.toLowerCase()} user. Please try again later.`,
        "error"
      );
    } finally {
      setIsSubmitting(false);
      setIsActionModalOpen(false);
    }
  };

  // Add a handler for hard delete
  const handleHardDelete = async (data) => {
    try {
      setIsSubmitting(true);
      const result = await deleteUserAccount(data);

      if (result) {
        addToast("User permanently deleted", "success");
        navigate("/admin/users");
      }
    } catch (err) {
      addToast(`Error: ${err.message}`, "error");
    } finally {
      setIsSubmitting(false);
      setIsHardDeleteModalOpen(false);
    }
  };

  // Update the renderActionButtons function to better reflect state restrictions
  const renderActionButtons = () => {
    if (!userDetails) return null;

    const actionOptions = [];

    // Only show Approve if user is registered and not already admin approved
    if (
      userDetails.registrationStatus !== "adminApproved" &&
      (userDetails.registrationStatus === "emailVerified" ||
        userDetails.registrationStatus === "registered")
    ) {
      const isUserActive = userDetails.status === "active";

      actionOptions.push(
        <Button
          key="approve"
          onClick={() => handleAction("Approve")}
          color="success"
          disabled={!isUserActive}
        >
          Approve User
        </Button>
      );
    }

    // For active users, show suspend, ban, and delete options
    if (userDetails.status === "active") {
      actionOptions.push(
        <Button
          key="suspend"
          onClick={() => handleAction("Suspend")}
          color="secondary"
        >
          Suspend User
        </Button>,
        <Button key="ban" onClick={() => handleAction("Ban")} color="secondary">
          Ban User
        </Button>,
        <Button
          key="delete"
          onClick={() => setIsHardDeleteModalOpen(true)}
          color="error"
        >
          Permanently Delete User
        </Button>
      );
    }

    // For suspended users, show ban option (can escalate) and restore
    if (userDetails.status === "suspended") {
      actionOptions.push(
        <Button key="ban" onClick={() => handleAction("Ban")} color="secondary">
          Ban User
        </Button>,
        <Button
          key="restore"
          onClick={() => handleAction("Restore")}
          color="success"
        >
          Restore User
        </Button>
      );
    }

    // For banned/deleted users, only show restore option
    if (userDetails.status === "banned" || userDetails.status === "deleted") {
      actionOptions.push(
        <Button
          key="restore"
          onClick={() => handleAction("Restore")}
          color="success"
        >
          Restore User
        </Button>
      );
    }

    // Only return the container if we have buttons to show
    return actionOptions.length > 0 ? (
      <div className={styles.actionButtons}>{actionOptions}</div>
    ) : null;
  };

  // Also add logging in the render section
  useEffect(() => {}, [userDetails]);

  const loadAdminActions = async () => {
    if (!userId) return;

    setAdminActionsLoading(true);
    setAdminActionsError(null);

    try {
      await fetchUserAdminActions(userId);
    } catch (error) {
      setAdminActionsError("Failed to load admin actions");
      console.error("Error loading user admin actions:", error);
    } finally {
      setAdminActionsLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "adminActions" && userId) {
      loadAdminActions();
    }
  }, [activeTab, userId]);

  // Add this helper function in the component
  const formatMetadata = (metadata) => {
    if (!metadata) return "";

    // Format dates in a more readable way
    const formatted = {};

    Object.entries(metadata).forEach(([key, value]) => {
      if (key.toLowerCase().includes("date") && value) {
        // Format date values
        formatted[key] = new Date(value).toLocaleString();
      } else if (key === "previousStatus") {
        // Capitalize status
        formatted[key] = value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        formatted[key] = value;
      }
    });

    return JSON.stringify(formatted, null, 2);
  };

  if (loading) {
    return (
      <div className={styles.userDetailContainer}>
        <div className={styles.userDetailHeader}>
          <button className={styles.backButton} onClick={handleBack}>
            <BsArrowLeft /> Back to Users
          </button>
        </div>
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (error || !userDetails) {
    return (
      <div className={styles.userDetailContainer}>
        <div className={styles.userDetailHeader}>
          <button className={styles.backButton} onClick={handleBack}>
            <BsArrowLeft /> Back to Users
          </button>
        </div>
        <div className={styles.errorContainer}>
          <BsExclamationTriangle size={48} />
          <p>{error || "User not found"}</p>
          <Button onClick={loadUser}>Try Again</Button>
        </div>
      </div>
    );
  }

  // Add this function to render content items
  const renderContentItems = () => {
    const { stories, lifeExperiences } = userContent;
    const hasStories = stories && stories.length > 0;
    const hasLifeExperiences = lifeExperiences && lifeExperiences.length > 0;

    if (!hasStories && !hasLifeExperiences) {
      return (
        <div className={styles.emptyState}>
          <p>This user has not created any content matching your filters.</p>
        </div>
      );
    }

    return (
      <>
        {hasStories && (
          <div className={styles.contentSection}>
            <h3>Stories</h3>
            <div className={styles.contentList}>
              {stories.map((story) => (
                <div
                  key={story._id}
                  className={styles.contentItem}
                  onClick={() => navigate(`/admin/content/story/${story._id}`)}
                  style={{ cursor: "pointer" }}
                >
                  <div className={styles.contentHeader}>
                    <h4>{story.title}</h4>
                    <div className={styles.headerActions}>
                      <span className={styles[`status-${story.status}`]}>
                        {story.status}
                      </span>
                      <span
                        className={styles.viewIcon}
                        title="View content details"
                      >
                        <BsEye />
                      </span>
                    </div>
                  </div>
                  <p className={styles.contentPreview}>
                    {story.text.substring(0, 150)}
                    {story.text.length > 150 ? "..." : ""}
                  </p>
                  <div className={styles.contentMeta}>
                    <span>
                      Created: {new Date(story.createdAt).toLocaleDateString()}
                    </span>
                    <span>Hugs: {story.hugs?.length || 0}</span>
                    <span>Reachouts: {story.reachouts?.length || 0}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {hasLifeExperiences && (
          <div className={styles.contentSection}>
            <h3>Life Experiences</h3>
            <div className={styles.contentList}>
              {lifeExperiences.map((exp) => (
                <div
                  key={exp._id}
                  className={styles.contentItem}
                  onClick={() =>
                    navigate(`/admin/content/lifeexperience/${exp._id}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div className={styles.contentHeader}>
                    <h4>
                      Experience from {exp.date.year}
                      {exp.date.month ? `-${exp.date.month}` : ""}
                    </h4>
                    <div className={styles.headerActions}>
                      <span className={styles[`status-${exp.status}`]}>
                        {exp.status}
                      </span>
                      <span
                        className={styles.viewIcon}
                        title="View content details"
                      >
                        <BsEye />
                      </span>
                    </div>
                  </div>
                  <p className={styles.contentPreview}>
                    {exp.story.substring(0, 150)}
                    {exp.story.length > 150 ? "..." : ""}
                  </p>
                  <div className={styles.contentMeta}>
                    <span>
                      Created: {new Date(exp.createdAt).toLocaleDateString()}
                    </span>
                    <span>Reachouts: {exp.reachouts?.length || 0}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  // First, create a separate function for the content filters
  const renderContentFilters = () => {
    return (
      <div className={styles.contentFilters}>
        <div className={styles.contentFilter}>
          <label htmlFor="contentTypeFilter">Content Type:</label>
          <select
            id="contentTypeFilter"
            value={contentType}
            onChange={(e) => {
              setContentType(e.target.value);
              setContentPage(1); // Reset to first page when changing filter
            }}
            disabled={contentLoading}
          >
            <option value="all">All Content</option>
            <option value="stories">Stories Only</option>
            <option value="lifeExperiences">Life Experiences Only</option>
          </select>
        </div>
      </div>
    );
  };

  const renderReportItem = (report) => {
    // Function to get a friendly name for the target type
    const getTargetTypeDisplay = (type) => {
      switch (type) {
        case "Story":
          return "Content (Story)";
        case "LifeExperience":
          return "Content (Life Experience)";
        case "User":
          return "User";
        case "Reachout":
          return "Reachout";
        case "Match":
          return "Match";
        case "Chat":
          return "Chat";
        default:
          return type;
      }
    };

    // Extract resource details from snapshot if available
    const getResourceDetails = () => {
      if (!report.snapshot) return null;

      switch (report.targetType) {
        case "Story":
          return (
            <div
              className={styles.resourceDetails}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering parent onClick
                if (report.targetId) {
                  navigate(`/admin/content/story/${report.targetId}`);
                }
              }}
              style={report.targetId ? { cursor: "pointer" } : {}}
            >
              <span className={styles.reportLabel}>Title:</span>
              <span className={styles.reportValue}>
                {report.snapshot.title || "No title"}
                {report.targetId && (
                  <BsEye
                    className={styles.viewIcon}
                    title="View content details"
                  />
                )}
              </span>
              {report.snapshot.text && (
                <>
                  <span className={styles.reportLabel}>Content:</span>
                  <p className={styles.reportDetailsText}>
                    {report.snapshot.text.substring(0, 100)}
                    {report.snapshot.text.length > 100 ? "..." : ""}
                  </p>
                </>
              )}
            </div>
          );

        case "LifeExperience":
          return (
            <div
              className={styles.resourceDetails}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering parent onClick
                if (report.targetId) {
                  navigate(`/admin/content/lifeexperience/${report.targetId}`);
                }
              }}
              style={report.targetId ? { cursor: "pointer" } : {}}
            >
              <span className={styles.reportLabel}>Date:</span>
              <span className={styles.reportValue}>
                {report.snapshot.date?.year}
                {report.snapshot.date?.month
                  ? `-${report.snapshot.date.month}`
                  : ""}
                {report.targetId && (
                  <BsEye
                    className={styles.viewIcon}
                    title="View content details"
                  />
                )}
              </span>
              {report.snapshot.story && (
                <>
                  <span className={styles.reportLabel}>Content:</span>
                  <p className={styles.reportDetailsText}>
                    {report.snapshot.story.substring(0, 100)}
                    {report.snapshot.story.length > 100 ? "..." : ""}
                  </p>
                </>
              )}
            </div>
          );

        case "User":
          return (
            <div className={styles.resourceDetails}>
              <span className={styles.reportLabel}>Username:</span>
              <span className={styles.reportValue}>
                {report.snapshot.username || "Unknown user"}
              </span>
            </div>
          );

        case "Reachout":
          return (
            <div className={styles.resourceDetails}>
              {report.snapshot.recentMessages &&
                report.snapshot.recentMessages.length > 0 && (
                  <>
                    <span className={styles.reportLabel}>Recent Message:</span>
                    <p className={styles.reportDetailsText}>
                      {report.snapshot.recentMessages[0].content?.text ||
                        "No message content"}
                    </p>
                  </>
                )}
            </div>
          );

        case "Match":
          return (
            <div className={styles.resourceDetails}>
              <span className={styles.reportLabel}>Status:</span>
              <span className={styles.reportValue}>
                {report.snapshot.status || "Unknown"}
              </span>
              {report.snapshot.recentMessages &&
                report.snapshot.recentMessages.length > 0 && (
                  <>
                    <span className={styles.reportLabel}>Recent Message:</span>
                    <p className={styles.reportDetailsText}>
                      {report.snapshot.recentMessages[0].content?.text ||
                        "No message content"}
                    </p>
                  </>
                )}
            </div>
          );

        case "Chat":
          return (
            <div className={styles.resourceDetails}>
              {report.snapshot.recentMessages &&
                report.snapshot.recentMessages.length > 0 && (
                  <>
                    <span className={styles.reportLabel}>Recent Message:</span>
                    <p className={styles.reportDetailsText}>
                      {report.snapshot.recentMessages[0].content?.text ||
                        "No message content"}
                    </p>
                  </>
                )}
            </div>
          );

        default:
          return null;
      }
    };

    return (
      <div key={report._id} className={styles.reportItem}>
        <div className={styles.reportHeader}>
          <div className={styles.reportType}>
            <span className={styles.reportLabel}>Reported:</span>
            <span className={styles.reportValue}>
              {getTargetTypeDisplay(report.targetType)}
            </span>
          </div>
          <span className={styles[`status-${report.status}`]}>
            {report.status}
          </span>
        </div>

        <div className={styles.reportContent}>
          <div className={styles.reportReason}>
            <span className={styles.reportLabel}>Reason:</span>
            <span className={styles.reportValue}>
              {report.reason.replace("_", " ")}
            </span>
          </div>

          {/* Add resource details section */}
          {getResourceDetails()}

          <div className={styles.reportDetails}>
            <span className={styles.reportLabel}>Details:</span>
            <p className={styles.reportDetailsText}>{report.details}</p>
          </div>

          <div className={styles.reportMeta}>
            <span>Reported: {formatDate(report.createdAt)}</span>
            <span>Age: {report.ageInDays} days</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.userDetailContainer}>
      <div className={styles.userDetailHeader}>
        <button className={styles.backButton} onClick={handleBack}>
          <BsArrowLeft /> Back to Users
        </button>
        <div className={styles.userStatus}>
          <span className={styles[`status-${userDetails.status}`]}>
            {userDetails.status}
          </span>
        </div>
      </div>

      <div className={styles.userDetailContent}>
        <div className={styles.userOverview}>
          <div className={styles.userDetailProfile}>
            <div className={styles.userAvatar}>
              <FaUser size={48} />
            </div>
            <div className={styles.userInfo}>
              <h2>{userDetails?.username || "No Username"}</h2>
              <p>{userDetails?.email}</p>
              <p className={styles.userId}>ID: {userDetails?._id}</p>
              <div className={styles.userMeta}>
                <span className={styles[`status-${userDetails?.status}`]}>
                  {userDetails?.status}
                </span>
                <span className={styles.userRole}>{userDetails?.role}</span>
                <span className={styles.userJoined}>
                  Joined:{" "}
                  {new Date(userDetails?.createdAt).toLocaleDateString()}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Position action buttons here, with proper spacing */}
        {renderActionButtons()}

        <div className={styles.userDetailTabs}>
          <button
            className={activeTab === "overview" ? styles.activeTab : ""}
            onClick={() => setActiveTab("overview")}
          >
            Overview
          </button>
          <button
            className={activeTab === "content" ? styles.activeTab : ""}
            onClick={() => setActiveTab("content")}
          >
            <FaBook /> Content
          </button>
          <button
            className={activeTab === "chats" ? styles.activeTab : ""}
            onClick={() => setActiveTab("chats")}
          >
            <FaComment /> Chats
          </button>
          <button
            className={activeTab === "reports" ? styles.activeTab : ""}
            onClick={() => setActiveTab("reports")}
          >
            <FaFlag /> Reports
          </button>
          <button
            className={activeTab === "activity" ? styles.activeTab : ""}
            onClick={() => setActiveTab("activity")}
          >
            <FaHistory /> Activity
          </button>
          <button
            className={activeTab === "adminActions" ? styles.activeTab : ""}
            onClick={() => setActiveTab("adminActions")}
          >
            <FaHistory /> Admin Actions
          </button>
        </div>

        <div className={styles.userDetailTabContent}>
          {activeTab === "overview" && (
            <div className={styles.overviewTab}>
              {loading ? (
                <div className={styles.loadingSpinner}>
                  <LoadingSpinner />
                </div>
              ) : !userDetails ? (
                <ErrorState
                  message="Could not load user content information"
                  onRetry={loadUserContent}
                />
              ) : (
                <>
                  <div className={styles.userDetailSection}>
                    <h3>Account Details</h3>
                    <div className={styles.detailGrid}>
                      <div className={styles.detailItem}>
                        <span className={styles.detailLabel}>Email:</span>
                        <span className={styles.detailValue}>
                          {userDetails.email}
                        </span>
                      </div>
                      <div className={styles.detailItem}>
                        <span className={styles.detailLabel}>Username:</span>
                        <span className={styles.detailValue}>
                          {userDetails.username || "Not set"}
                        </span>
                      </div>
                      <div className={styles.detailItem}>
                        <span className={styles.detailLabel}>Status:</span>
                        <span className={styles.detailValue}>
                          {userDetails.status}
                        </span>
                      </div>
                      <div className={styles.detailItem}>
                        <span className={styles.detailLabel}>Role:</span>
                        <span className={styles.detailValue}>
                          {userDetails.role}
                        </span>
                      </div>
                      <div className={styles.detailItem}>
                        <span className={styles.detailLabel}>
                          Registration Status:
                        </span>
                        <span className={styles.detailValue}>
                          {REGISTRATION_STATUS_MAP[
                            userDetails.registrationStatus
                          ] || userDetails.registrationStatus}
                        </span>
                      </div>
                      <div className={styles.detailItem}>
                        <span className={styles.detailLabel}>Created:</span>
                        <span className={styles.detailValue}>
                          {new Date(userDetails.createdAt).toLocaleString()}
                        </span>
                      </div>
                      {userDetails.location && (
                        <div className={styles.detailItem}>
                          <span className={styles.detailLabel}>Location:</span>
                          <span className={styles.detailValue}>
                            {userDetails.location.formattedName ||
                              userDetails.location.name}{" "}
                            ({userDetails.location.code})
                          </span>
                        </div>
                      )}
                      {userDetails.gender && (
                        <div className={styles.detailItem}>
                          <span className={styles.detailLabel}>Gender:</span>
                          <span className={styles.detailValue}>
                            {userDetails.gender.charAt(0).toUpperCase() +
                              userDetails.gender.slice(1)}
                          </span>
                        </div>
                      )}
                      {userDetails.birthdate && (
                        <div className={styles.detailItem}>
                          <span className={styles.detailLabel}>Birthdate:</span>
                          <span className={styles.detailValue}>
                            {new Date(
                              userDetails.birthdate
                            ).toLocaleDateString()}
                          </span>
                        </div>
                      )}
                      {userDetails.subscription && (
                        <div className={styles.detailItem}>
                          <span className={styles.detailLabel}>
                            Subscription:
                          </span>
                          <span className={styles.detailValue}>
                            {userDetails.subscription.charAt(0).toUpperCase() +
                              userDetails.subscription.slice(1)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.userDetailSection}>
                    <h3>Activity Summary</h3>
                    <div className={styles.activitySummary}>
                      <div className={styles.activityItem}>
                        <span className={styles.activityCount}>
                          {userDetails?.content?.storiesCount || 0}
                        </span>
                        <span className={styles.activityLabel}>Stories</span>
                      </div>
                      <div className={styles.activityItem}>
                        <span className={styles.activityCount}>
                          {userDetails?.content?.lifeExperiencesCount || 0}
                        </span>
                        <span className={styles.activityLabel}>
                          Life Experiences
                        </span>
                      </div>
                      <div className={styles.activityItem}>
                        <span className={styles.activityCount}>
                          {userDetails?.content?.reportsCount || 0}
                        </span>
                        <span className={styles.activityLabel}>Reports</span>
                      </div>
                      <div className={styles.activityItem}>
                        <span className={styles.activityCount}>
                          {userDetails?.content?.chatsCount || 0}
                        </span>
                        <span className={styles.activityLabel}>Chats</span>
                      </div>
                    </div>
                  </div>

                  {/* Add a new section for user scores if they exist */}
                  {userDetails.scores && (
                    <div className={styles.userDetailSection}>
                      <h3>User Scores</h3>
                      <div className={styles.scoresGrid}>
                        <div className={styles.scoreItem}>
                          <span className={styles.scoreValue}>
                            {userDetails.scores.trustScore || 0}
                          </span>
                          <span className={styles.scoreLabel}>Trust Score</span>
                        </div>
                        <div className={styles.scoreItem}>
                          <span className={styles.scoreValue}>
                            {userDetails.scores.warmthScore || 0}
                          </span>
                          <span className={styles.scoreLabel}>
                            Warmth Score
                          </span>
                        </div>
                        <div className={styles.scoreItem}>
                          <span className={styles.scoreValue}>
                            {userDetails.scores.totalReviews || 0}
                          </span>
                          <span className={styles.scoreLabel}>
                            Total Reviews
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {activeTab === "content" && (
            <div className={styles.contentTab}>
              {/* Always show filters at the top */}
              {renderContentFilters()}

              {contentLoading ? (
                <div className={styles.loadingSpinner}>
                  <LoadingSpinner />
                </div>
              ) : contentError ? (
                <div className={styles.errorState}>
                  <BsExclamationTriangle size={32} />
                  <p>{contentError}</p>
                  <Button onClick={loadUserContent}>Try Again</Button>
                </div>
              ) : (
                renderContentItems()
              )}
            </div>
          )}

          {activeTab === "chats" && (
            <div className={styles.chatsTab}>
              <div className={styles.userDetailSection}>
                <h3>Chat History</h3>
                <div className={styles.emptyState}>
                  <p>No chat history available.</p>
                </div>
              </div>
            </div>
          )}

          {activeTab === "reports" && (
            <div className={styles.reportsTab}>
              {reportsLoading ? (
                <div className={styles.loadingSpinner}>
                  <LoadingSpinner />
                </div>
              ) : reportsError ? (
                <div className={styles.errorState}>
                  <BsExclamationTriangle size={32} />
                  <p>{reportsError}</p>
                  <Button onClick={loadUserReports}>Try Again</Button>
                </div>
              ) : (
                <>
                  <div className={styles.userDetailSection}>
                    <h3>Reports Filed by User</h3>
                    {userReports.filed.reports.length === 0 ? (
                      <div className={styles.emptyState}>
                        <p>No reports filed by this user.</p>
                      </div>
                    ) : (
                      <div className={styles.reportsList}>
                        {userReports.filed.reports.map(renderReportItem)}
                      </div>
                    )}
                  </div>

                  <div className={styles.userDetailSection}>
                    <h3>Reports Against User</h3>
                    {userReports.received.reports.length === 0 ? (
                      <div className={styles.emptyState}>
                        <p>No reports filed against this user.</p>
                      </div>
                    ) : (
                      <div className={styles.reportsList}>
                        {userReports.received.reports.map(renderReportItem)}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          )}

          {activeTab === "activity" && (
            <div className={styles.activityTab}>
              <div className={styles.userDetailSection}>
                <h3>User Initiated Activity</h3>
                {userActivityLoading ? (
                  <div className={styles.loadingSpinner}>
                    <LoadingSpinner />
                  </div>
                ) : userActivityError ? (
                  <ErrorState
                    message={userActivityError}
                    onRetry={loadUserActivity}
                  />
                ) : userActivityLog.length === 0 ? (
                  <div className={styles.emptyState}>
                    <p>No recent activity recorded.</p>
                  </div>
                ) : (
                  <div className={styles.adminActionsList}>
                    {userActivityLog.map((log) => (
                      <div
                        key={log._id}
                        className={styles.adminActionItem}
                        data-action-type={log.action}
                      >
                        <div className={styles.adminActionHeader}>
                          <span className={styles.adminActionType}>
                            {log.action.replace(/_/g, " ")}
                          </span>
                          <span className={styles.adminActionDate}>
                            {formatDate(log.createdAt)}
                          </span>
                        </div>
                        <div className={styles.adminActionContent}>
                          {log.details && log.details.reason && (
                            <div className={styles.adminActionReason}>
                              <span className={styles.adminActionLabel}>
                                Reason:
                              </span>
                              <span className={styles.adminActionValue}>
                                {log.details.reason}
                              </span>
                            </div>
                          )}
                          {log.metadata &&
                            Object.keys(log.metadata).length > 0 && (
                              <div className={styles.adminActionMetadata}>
                                <span className={styles.adminActionLabel}>
                                  Details:
                                </span>
                                <pre
                                  className={styles.adminActionMetadataContent}
                                >
                                  {formatMetadata(log.metadata)}
                                </pre>
                              </div>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          {activeTab === "adminActions" && (
            <div className={styles.adminActionsTab}>
              <div className={styles.userDetailSection}>
                <h3>Admin Actions</h3>
                {adminActionsLoading ? (
                  <div className={styles.loadingSpinner}>
                    <LoadingSpinner />
                  </div>
                ) : adminActionsError ? (
                  <div className={styles.errorState}>
                    <BsExclamationTriangle size={32} />
                    <p>{adminActionsError}</p>
                    <Button onClick={loadAdminActions}>Try Again</Button>
                  </div>
                ) : userAdminActions.length === 0 ? (
                  <div className={styles.emptyState}>
                    <p>No admin actions have been performed on this user.</p>
                  </div>
                ) : (
                  <div className={styles.adminActionsList}>
                    {userAdminActions.map((action) => (
                      <div
                        key={action.id}
                        className={styles.adminActionItem}
                        data-action-type={action.actionType}
                      >
                        <div className={styles.adminActionHeader}>
                          <span className={styles.adminActionType}>
                            {action.actionType.replace(/_/g, " ")}
                          </span>
                          <span className={styles.adminActionDate}>
                            {new Date(action.timestamp).toLocaleDateString()} at{" "}
                            {new Date(action.timestamp).toLocaleTimeString()}
                          </span>
                        </div>
                        <div className={styles.adminActionContent}>
                          <div className={styles.adminActionReason}>
                            <span className={styles.adminActionLabel}>
                              Reason:
                            </span>
                            <span className={styles.adminActionValue}>
                              {action.reason}
                            </span>
                          </div>
                          <div className={styles.adminActionModerator}>
                            <span className={styles.adminActionLabel}>
                              Performed by:
                            </span>
                            <span className={styles.adminActionValue}>
                              {action.moderator?.username || "System"}
                            </span>
                          </div>
                          {action.metadata &&
                            Object.keys(action.metadata).length > 0 && (
                              <div className={styles.adminActionMetadata}>
                                <span className={styles.adminActionLabel}>
                                  Details:
                                </span>
                                <pre
                                  className={styles.adminActionMetadataContent}
                                >
                                  {formatMetadata(action.metadata)}
                                </pre>
                              </div>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Add the shared modal component */}
      <UserActionModal
        isOpen={isActionModalOpen}
        onClose={() => setIsActionModalOpen(false)}
        actionType={actionType}
        user={userDetails}
        onSubmit={handleActionSubmit}
        isSubmitting={isSubmitting}
      />

      <HardDeleteConfirmModal
        isOpen={isHardDeleteModalOpen}
        onClose={() => setIsHardDeleteModalOpen(false)}
        user={userDetails}
        onConfirm={handleHardDelete}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default UserDetail;
