import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as API from "api/stories";

const useStoryCRUD = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("idle");
  const [statusMessage, setStatusMessage] = useState("");

  // Memoize the fetchStory function to maintain referential equality
  const fetchStory = useCallback(async (storyId) => {
    try {
      const story = await API.fetchStoryById(storyId);
      return story;
    } catch (err) {
      console.error("Error fetching story:", err);
      throw err;
    }
  }, []);

  const submitStory = useCallback(
    async (storyData) => {
      try {
        setStatus("submitting");
        setStatusMessage("Submitting story...");
        const result = await API.submitStory(storyData);
        setStatus("success");
        setStatusMessage("Story submitted successfully!");
        navigate(`/stories/${result._id}`);
      } catch (err) {
        console.error("Error submitting story:", err);
        setStatus("error");
        setStatusMessage("Error submitting story. Please try again.");
      }
    },
    [navigate]
  );

  const updateStory = useCallback(
    async (storyId, storyData) => {
      try {
        setStatus("submitting");
        setStatusMessage("Updating story...");
        await API.updateStory(storyId, storyData);
        setStatus("success");
        setStatusMessage("Story updated successfully!");
        navigate(`/stories/${storyId}`);
      } catch (err) {
        console.error("Error updating story:", err);
        setStatus("error");
        setStatusMessage("Error updating story. Please try again.");
      }
    },
    [navigate]
  );

  const deleteStory = useCallback(
    async (storyId) => {
      try {
        setStatus("deleting");
        setStatusMessage("Deleting story...");
        await API.deleteStory(storyId);
        setStatus("success");
        setStatusMessage("Story deleted successfully!");
        navigate("/stories"); // Navigate to the stories list page after deletion
      } catch (err) {
        console.error("Error deleting story:", err);
        setStatus("error");
        setStatusMessage("Error deleting story. Please try again.");
      }
    },
    [navigate]
  );

  return {
    status,
    statusMessage,
    fetchStory,
    submitStory,
    updateStory,
    deleteStory,
  };
};

export default useStoryCRUD;
