import DOMPurify from "dompurify";
import React, { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./StoryCard.module.css";
import { formatRelativeTime } from "utils/dates";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { GiBilledCap } from "react-icons/gi";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ReusableTooltip/ReusableTooltip";
import { PiPlugsConnectedFill, PiHandHeart } from "react-icons/pi";
import cn from "classnames";
import ReachOutModal from "components/ReachOutModal/ReachOutModal";
import ReportModal from "components/ReportModal/ReportModal";
import { reportStory } from "api/reports";
import useStoryHug from "hooks/useStoryHug";
import useStory from "hooks/useStory";
import UserInfoDialog from "components/UserInfoDialog/UserInfoDialog";
import { FaHeart } from "react-icons/fa";
import useMediaQuery from "hooks/useMediaQuery";
import DeleteConfirmationModal from "components/DeleteConfirmationModal/DeleteConfirmationModal";

/**
 * StoryCard component displays a single story with its details.
 * It allows users to hug the story and view highlighted text.
 *
 * @typedef {Object} Props - The component props.
 * @property {Object} story - The story object containing details to display.
 * @property {Object} userData - The user data of the currently logged-in user.
 * @property {React.Ref} ref - The ref object forwarded to the component.
 * @returns {JSX.Element} The rendered StoryCard component.
 */
/**
 * @type {React.ForwardRefExoticComponent<Props & React.RefAttributes<unknown>>}
 * @description This component is responsible for rendering individual chat messages, including their content, sender information, and any associated metadata such as timestamps and shared tags.
 */

// Add semantic score display component
const SemanticScoreSection = ({ scores }) => {
  if (!scores?.semantic) return null;

  // Convert to percentage and round to nearest whole number
  const scorePercentage = Math.round(scores.semantic * 100);

  return (
    <div className={styles.semanticScore}>
      <span className={styles.scoreLabel}>Match:</span>
      <span className={styles.scoreValue}>{scorePercentage}%</span>
    </div>
  );
};

// Base Story Card Component
export const BaseStoryCard = ({
  story,
  actionMenuOptions,
  className,
  theme = "light",
  onHugClick,
  isHugged,
  hugsCount,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("sm");
  const isSmallWidth = useMediaQuery("xs");

  const StoryContent = () => (
    <div className={styles.content}>
      <h2
        className={styles.title}
        onClick={() => navigate(`/stories/${story._id}`)}
        role="link"
        tabIndex={0}
      >
        {story.title}
      </h2>
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(story.body) }}
      />
    </div>
  );

  const TagsSection = () => {
    if (!story.tags?.length) return null;

    return (
      <div className={styles.tags}>
        {story.tags.map((tag) => (
          <div key={tag._id} className={styles.tag}>
            {tag.name}
          </div>
        ))}
      </div>
    );
  };

  const AuthorSection = () => {
    const [showUserInfo, setShowUserInfo] = useState(false);

    if (!story.author) return null;

    const shouldUsePseudonym = story.author.username === "anonymous";

    const authorData = {
      ...story.author,
      pseudonym: story.authorPseudonym,
    };

    return (
      <>
        <span
          className={styles.author}
          onClick={() => setShowUserInfo(true)}
          role="button"
          tabIndex={0}
        >
          {shouldUsePseudonym ? (
            <>
              <GiBilledCap className={styles.anonymousIcon} />
              <span>{story.authorPseudonym}</span>
            </>
          ) : (
            story.author.username
          )}
        </span>

        <UserInfoDialog
          data={authorData}
          open={showUserInfo}
          onOpenChange={setShowUserInfo}
        />
      </>
    );
  };

  const MetadataSection = () => (
    <div className={styles.metadata}>
      <div className={styles.date}>
        written {formatRelativeTime(story.createdAt)}
      </div>
      <div className={styles.statusIndicators}>
        {story.isReachedOut && (
          <Tooltip>
            <TooltipTrigger>
              <div className={styles.statusIndicator}>
                <PiHandHeart className={styles.statusIcon} />
              </div>
            </TooltipTrigger>
            <TooltipContent>Reached Out</TooltipContent>
          </Tooltip>
        )}
        {story.isConnected && (
          <Tooltip>
            <TooltipTrigger>
              <div className={styles.statusIndicator}>
                <PiPlugsConnectedFill className={styles.statusIcon} />
              </div>
            </TooltipTrigger>
            <TooltipContent>Connected</TooltipContent>
          </Tooltip>
        )}
      </div>
    </div>
  );

  return (
    <div
      data-theme={theme}
      className={cn(styles.storyCard, className, {
        [styles.mobile]: isMobile,
        [styles.smallWidth]: isSmallWidth,
      })}
      role="article"
      aria-label={`Story by ${
        story.anonymized ? story.authorPseudonym : story.author?.username
      }`}
    >
      <div className={styles.inner}>
        <div className={styles.headerRow}>
          <StoryContent />
          {actionMenuOptions && (
            <div className={styles.actionMenu}>
              <ReusableActionMenu
                options={actionMenuOptions}
                icon={IoEllipsisHorizontal}
                theme={theme === "light" ? "light" : "dark"}
              />
            </div>
          )}
        </div>

        {/* Add semantic score display */}
        {story.scores?.semantic && (
          <SemanticScoreSection scores={story.scores} />
        )}

        <TagsSection />
        <div className={styles.footer}>
          <AuthorSection />
          <MetadataSection />
        </div>
        <div className={styles.hugSection}>
          <button
            className={cn(styles.hugButton, { [styles.hugged]: isHugged })}
            onClick={onHugClick}
            aria-label={isHugged ? "Remove hug" : "Give hug"}
          >
            <FaHeart />
            <span className={styles.hugCount}>{hugsCount}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

// Owner Card Component
const OwnerStoryCard = ({
  story,
  onUpdate,
  onDelete,
  theme,
  onHugClick,
  isHugged,
  hugsCount,
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const {
    story: storyData,
    error,
    status,
    statusMessage,
    updateStory,
    deleteStory,
  } = useStory(story);

  const handleEdit = () => {
    navigate(`/stories/${story._id}/edit`);
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      // First perform the API delete operation using the card's own hook
      await deleteStory(story._id);

      // Then notify parent with special flag indicating API call was already made
      // This allows the parent to update its state without making another API call
      if (onDelete) {
        onDelete(story._id, { apiCallCompleted: true });
      }

      setShowDeleteConfirmation(false);
    } catch (err) {
      console.error("Error deleting story:", err);
    }
  };

  const actionMenuOptions = [
    { label: "Edit", onClick: handleEdit },
    { label: "Delete", onClick: handleDelete },
  ];

  return (
    <>
      <BaseStoryCard
        story={story}
        actionMenuOptions={actionMenuOptions}
        theme={theme}
        onHugClick={onHugClick}
        isHugged={isHugged}
        hugsCount={hugsCount}
      />
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeleteConfirm}
          itemName="story"
          status={status}
          statusMessage={statusMessage}
        />
      )}
    </>
  );
};

// Non-Owner Card Component
const NonOwnerStoryCard = ({
  story,
  theme,
  onHugClick,
  isHugged,
  hugsCount,
}) => {
  const [isReachOutModalOpen, setIsReachOutModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const handleReachOut = () => {
    setIsReachOutModalOpen(true);
  };

  const handleReport = () => {
    setIsReportModalOpen(true);
  };

  const handleSubmitReport = async (reportData) => {
    return reportStory({
      targetId: story._id,
      ...reportData,
    });
  };

  const actionMenuOptions = [
    { label: "Reach Out", onClick: handleReachOut },
    { label: "Report", onClick: handleReport },
  ];

  return (
    <>
      <BaseStoryCard
        story={story}
        actionMenuOptions={actionMenuOptions}
        theme={theme}
        onHugClick={onHugClick}
        isHugged={isHugged}
        hugsCount={hugsCount}
      />
      <ReachOutModal
        isOpen={isReachOutModalOpen}
        onClose={() => setIsReachOutModalOpen(false)}
        type="Story"
        resource={story}
      />
      {isReportModalOpen && (
        <ReportModal
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSubmit={handleSubmitReport}
          targetType="Story"
          targetData={story}
          targetId={story._id}
        />
      )}
    </>
  );
};

// Main StoryCard Component
const StoryCard = forwardRef(
  ({ story, userData, theme = "transparent", onDelete }, ref) => {
    const { isHugged, hugsLength, handleHugClick } = useStoryHug(
      story,
      userData
    );
    const isAuthor = story.author?._id === userData?._id;

    return (
      <div ref={ref}>
        {isAuthor ? (
          <OwnerStoryCard
            story={story}
            theme={theme}
            onHugClick={handleHugClick}
            isHugged={isHugged}
            hugsCount={hugsLength}
            onDelete={onDelete}
          />
        ) : (
          <NonOwnerStoryCard
            story={story}
            theme={theme}
            onHugClick={handleHugClick}
            isHugged={isHugged}
            hugsCount={hugsLength}
          />
        )}
      </div>
    );
  }
);

StoryCard.displayName = "StoryCard";

export default StoryCard;
