import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./StoryForm.module.css";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "contexts/ToastContext";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import { FaArrowLeft } from "react-icons/fa";
import TagInput from "components/TagInput/TagInput";
import * as TAGS_API from "api/tags";
import _ from "lodash";
import useMediaQuery from "hooks/useMediaQuery";
import DeleteConfirmationModal from "components/DeleteConfirmationModal/DeleteConfirmationModal";
import useStory from "hooks/useStory";

const SimpleMenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className={styles.menuBar}>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? styles.isActive : ""}
      >
        B
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? styles.isActive : ""}
      >
        I
      </button>
      <button
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={editor.isActive("underline") ? styles.isActive : ""}
      >
        U
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={
          editor.isActive("heading", { level: 1 }) ? styles.isActive : ""
        }
      >
        H1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={
          editor.isActive("heading", { level: 2 }) ? styles.isActive : ""
        }
      >
        H2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive("blockquote") ? styles.isActive : ""}
      >
        " "
      </button>
    </div>
  );
};

const SimpleTitleInput = ({ value, onChange, placeholder }) => {
  return (
    <input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={styles.titleInput}
      placeholder={placeholder}
    />
  );
};

function StoryForm({
  initialData = {},
  onSubmit,
  status: externalStatus,
  statusMessage: externalStatusMessage,
  initialTheme = "dark",
  onDelete,
}) {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const isEditMode = Boolean(initialData?._id);

  const {
    status: hookStatus,
    statusMessage: hookStatusMessage,
    deleteStory,
  } = useStory(initialData);

  const status = externalStatus || hookStatus;
  const statusMessage = externalStatusMessage || hookStatusMessage;

  const [title, setTitle] = useState(initialData?.title || "");
  const [bodyContent, setBodyContent] = useState("");
  const [selectedTags, setSelectedTags] = useState(initialData?.tags || []);
  const [errors, setErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [recommendedTags, setRecommendedTags] = useState([]);
  const [isLoadingRecommendedTags, setIsLoadingRecommendedTags] =
    useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteStory(initialData._id);
      navigate("/write");
    } catch (err) {
      console.error("Error deleting story:", err);
    }
  };

  // Initialize editor
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: { levels: [1, 2, 3] },
      }),
      Placeholder.configure({
        placeholder: "Write your story...",
      }),
      Underline,
    ],
    content: bodyContent || initialData?.body || "",
    onUpdate: ({ editor }) => {
      setBodyContent(editor.getHTML());
      if (errors.body) {
        setErrors((prev) => ({ ...prev, body: "" }));
      }
    },
    autofocus: "end",
  });

  // Focus editor on mount
  useEffect(() => {
    if (editor) {
      setTimeout(() => {
        editor.commands.focus("end");
      }, 100);
    }
  }, [editor]);

  // Load initial data
  useEffect(() => {
    if (initialData && !hasSubmitted) {
      setTitle(initialData.title || "");
      setBodyContent(initialData.body || "");
      setSelectedTags(initialData.tags || []);
    }
  }, [initialData, hasSubmitted]);

  // Fetch tag recommendations
  useEffect(() => {
    const fetchRecommendedTags = async (text) => {
      if (!text.trim()) {
        setRecommendedTags([]);
        return;
      }

      setIsLoadingRecommendedTags(true);
      try {
        const data = await TAGS_API.suggestTags({
          text,
          limit: 5,
        });
        setRecommendedTags(data.tags);
      } catch (err) {
        console.error("Error fetching tag recommendations:", err);
        setRecommendedTags([]);
      } finally {
        setIsLoadingRecommendedTags(false);
      }
    };

    const debouncedFetch = _.debounce(fetchRecommendedTags, 500);
    const combinedText = `${title} ${bodyContent.replace(/<[^>]*>/g, "")}`;
    debouncedFetch(combinedText);

    return () => debouncedFetch.cancel();
  }, [title, bodyContent]);

  const handleTitleChange = (value) => {
    setTitle(value);
    if (errors.title) {
      setErrors((prev) => ({ ...prev, title: "" }));
    }
  };

  const handleTagsChange = (tags) => {
    setSelectedTags(tags);
    if (errors.tags) {
      setErrors((prev) => ({ ...prev, tags: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!title.trim()) {
      newErrors.title = "Please add a title for your story";
      isValid = false;
    }

    const plainTextBody = bodyContent.replace(/<[^>]*>/g, "").trim();
    if (!plainTextBody) {
      newErrors.body = "Please write something in your story";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasSubmitted(true);

    if (!validateForm()) {
      addToast("Please fix the errors in the form", "error");
      return;
    }

    try {
      const tagIds = selectedTags.map((tag) => tag._id);
      if (typeof onSubmit === "function") {
        await onSubmit({
          title,
          body: bodyContent,
          tags: tagIds,
          anonymized: true,
        });
      }
    } catch (error) {
      console.error("Error submitting story:", error);
      addToast("Failed to save story", "error");
      setHasSubmitted(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (status === "success" && statusMessage) {
      addToast(statusMessage, "success");
    } else if (status === "error" && statusMessage) {
      addToast(statusMessage, "error");
    }
  }, [status, statusMessage, addToast]);

  // Reference to the editor container for resizing
  const editorContainerRef = useRef(null);

  // Handle resize functionality
  useEffect(() => {
    const editorContainer = editorContainerRef.current;
    const resizeHandle = editorContainer?.querySelector(
      `.${styles.resizeHandle}`
    );

    if (!editorContainer || !resizeHandle) return;

    let startY = 0;
    let startHeight = 0;

    const startResize = (e) => {
      startY = e.clientY;
      startHeight = parseInt(
        document.defaultView.getComputedStyle(editorContainer).height,
        10
      );
      document.addEventListener("mousemove", resize);
      document.addEventListener("mouseup", stopResize);
      e.preventDefault();
    };

    const resize = (e) => {
      const newHeight = startHeight + e.clientY - startY;
      if (newHeight > 200) {
        // Minimum height
        editorContainer.style.height = `${newHeight}px`;
      }
    };

    const stopResize = () => {
      document.removeEventListener("mousemove", resize);
      document.removeEventListener("mouseup", stopResize);
    };

    resizeHandle.addEventListener("mousedown", startResize);

    return () => {
      resizeHandle.removeEventListener("mousedown", startResize);
      document.removeEventListener("mousemove", resize);
      document.removeEventListener("mouseup", stopResize);
    };
  }, []);

  return (
    <div className={styles.simpleContainer}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={handleBack}>
          <FaArrowLeft />
        </button>
        <h1>{isEditMode ? "Edit Your Story" : "Write a New Story"}</h1>
        {isEditMode && (
          <Button
            type="button"
            onClick={handleDeleteClick}
            color="error"
            className={styles.deleteButton}
          >
            Delete
          </Button>
        )}
      </div>

      <form className={styles.simpleForm}>
        <div className={styles.formSection}>
          <label>Title</label>
          <SimpleTitleInput
            value={title}
            onChange={handleTitleChange}
            placeholder="Give your story a title..."
          />
          {errors.title && <div className={styles.error}>{errors.title}</div>}
        </div>

        <div className={styles.formSection}>
          <label>Story</label>
          <div className={styles.editorContainer} ref={editorContainerRef}>
            <SimpleMenuBar editor={editor} />
            <div className={styles.editor}>
              <EditorContent editor={editor} />
              <div className={styles.resizeHandle}></div>
            </div>
          </div>
          {errors.body && <div className={styles.error}>{errors.body}</div>}
        </div>

        <div className={styles.formSection}>
          <label>Tags (Optional)</label>
          <TagInput
            selectedTags={selectedTags}
            setSelectedTags={handleTagsChange}
            recommendedTags={recommendedTags}
            theme="navbar-brown"
          />
          <p className={styles.helper}>Tags help others find your story</p>
          {errors.tags && <div className={styles.error}>{errors.tags}</div>}
        </div>

        {status === "submitting" && <p className={styles.status}>Saving...</p>}
        {status === "error" && (
          <p className={styles.errorStatus}>{statusMessage}</p>
        )}
        {status === "success" && (
          <p className={styles.successStatus}>{statusMessage}</p>
        )}

        <div className={styles.actions}>
          <Button
            type="button"
            onClick={handleBack}
            color="secondary"
            disabled={status === "submitting"}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={status === "submitting"}
          >
            {status === "submitting"
              ? "Saving..."
              : isEditMode
              ? "Update Story"
              : "Publish Story"}
          </Button>
        </div>
      </form>

      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeleteConfirm}
          itemName="story"
          status={hookStatus}
          statusMessage={hookStatusMessage}
        />
      )}
    </div>
  );
}

export default StoryForm;
