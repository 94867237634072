// packages/client/src/pages/ProtectedRoutes/ProtectedRoutes.jsx
import React, { useMemo, useEffect, useContext } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import { ToastContext } from "contexts/ToastContext";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import PageWrapper from "components/PageWrapper/PageWrapper";

/**
 * A simplified ProtectedRoutes component that handles authentication and permissions
 * using a hierarchical approach to permissions
 */
const ProtectedRoutes = ({
  redirectTo = "/",
  loggedInOnly = false,
  registeredOnly = false,
  emailVerifiedOnly = false,
  adminApprovedOnly = false,
  activeOnly = false,
  isAdmin = false,
}) => {
  const { userData, loading } = useUserContext();
  const { addToast } = useContext(ToastContext);
  const location = useLocation();

  // Memoize the redirect decision to avoid unnecessary calculations
  const redirectInfo = useMemo(() => {
    // Show loading state while checking authentication
    if (loading) return { path: null, reason: null };

    // Basic authentication check
    if (loggedInOnly && !userData) {
      return {
        path: redirectTo,
        reason: "Please log in to access this page.",
      };
    }

    // Handle deleted accounts first - ALWAYS redirect to reactivate
    // EXCEPT for the reactivate page itself
    if (
      userData &&
      userData.status === "deleted" &&
      location.pathname !== "/reactivate"
    ) {
      return {
        path: "/reactivate",
        reason: "Your account has been deleted. Please review your options.",
      };
    }

    // Admin role check (separate from the hierarchy)
    if (isAdmin && (!userData || userData.role !== "admin")) {
      return {
        path: "/",
        reason: "You don't have permission to access the admin area.",
      };
    }

    // Hierarchical permission checks
    if (userData) {
      // Level 1: Registration
      if (registeredOnly && !userData.isRegistered) {
        return {
          path: "/register",
          reason: "Please complete your registration to continue.",
        };
      }

      // Level 2: Email verification - Special handling for profile pages
      if (emailVerifiedOnly) {
        if (!userData.isEmailVerified) {
          // Check if this is a profile page
          const profileMatch = location.pathname.match(/^\/user\/([^/]+)/);

          if (profileMatch) {
            const requestedUsername = profileMatch[1];

            // Allow access to own profile, redirect for others' profiles
            if (requestedUsername !== userData.username) {
              return {
                path: `/user/${userData.username}`,
                reason: "Please verify your email to view other profiles.",
              };
            }
          } else {
            // For non-profile pages requiring verification
            return {
              path: `/user/${userData.username}`,
              reason:
                "Please verify your email address to access this feature.",
            };
          }
        }
      }

      // Level 3: Admin approval
      if (adminApprovedOnly && !userData.isAdminApproved) {
        return {
          path: "/profile",
          reason: "Your account is pending admin approval.",
        };
      }

      // Level 4: Active account status (handles suspended and banned, as deleted is handled above)
      if (activeOnly && !userData.isActive && userData.status !== "deleted") {
        // Special case: allow users to access their own profile
        const profileMatch = location.pathname.match(/^\/user\/([^/]+)/);
        const isOwnProfile =
          profileMatch && profileMatch[1] === userData.username;

        if (!isOwnProfile) {
          // For all other routes, redirect to profile
          return {
            path: `/user/${userData.username}`,
            reason: `Your account is ${userData.status}. Some features are restricted.`,
          };
        }
      }
    }

    // All checks passed, no redirect needed
    return { path: null, reason: null };
  }, [
    userData,
    loading,
    loggedInOnly,
    registeredOnly,
    emailVerifiedOnly,
    adminApprovedOnly,
    activeOnly,
    isAdmin,
    redirectTo,
    location.pathname,
  ]);

  // Show toast notification when redirecting
  useEffect(() => {
    if (redirectInfo.path && redirectInfo.reason) {
      addToast(redirectInfo.reason, "info");
    }
  }, [redirectInfo.path, redirectInfo.reason, addToast]);

  // Show loading state while checking authentication
  if (loading) {
    return (
      <PageWrapper>
        <LoadingContainer>
          <LoadingMessage message="Authenticating user..." />
        </LoadingContainer>
      </PageWrapper>
    );
  }

  // Redirect if needed
  if (redirectInfo.path) {
    return <Navigate to={redirectInfo.path} />;
  }

  // All checks passed, render the protected route
  return <Outlet />;
};

export default ProtectedRoutes;
