import { useState, useEffect } from "react";
import * as LifeExpAPI from "api/lifeExperiences";
import { useToastContext } from "contexts/ToastContext";

export const useWriteLifeExperiences = () => {
  const [lifeExperiences, setLifeExperiences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToast } = useToastContext();

  // Fetch all life experiences
  const fetchLifeExperiences = async () => {
    try {
      setLoading(true);
      const data = await LifeExpAPI.fetchLifeExperiences();
      // Sort by updatedAt in descending order (newest first)
      const sortedExperiences = data.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setLifeExperiences(sortedExperiences);
      return sortedExperiences;
    } catch (err) {
      setError(err.message);
      addToast(err.message || "Error loading life experiences", "error");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Create a new life experience
  const createLifeExperience = async (formData) => {
    try {
      setLoading(true);
      const newLifeExp = await LifeExpAPI.createLifeExperience(formData);
      setLifeExperiences((prev) => [newLifeExp, ...prev]); // Add to beginning
      addToast("Life experience created successfully", "success");
      return newLifeExp;
    } catch (err) {
      setError(err.message);
      // Simply use the error message directly from the backend
      addToast(err.message || "Error creating life experience", "error");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Update an existing life experience
  const updateLifeExperience = async (id, formData) => {
    try {
      setLoading(true);
      const updatedLifeExp = await LifeExpAPI.updateLifeExperience(
        id,
        formData
      );
      setLifeExperiences((prev) =>
        prev.map((exp) => (exp._id === id ? updatedLifeExp : exp))
      );
      addToast("Life experience updated successfully", "success");
      return updatedLifeExp;
    } catch (err) {
      setError(err.message);
      addToast(err.message || "Error updating life experience", "error");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Delete a life experience
  const deleteLifeExperience = async (id) => {
    try {
      setLoading(true);
      await LifeExpAPI.deleteLifeExperience(id);
      setLifeExperiences((prev) => prev.filter((exp) => exp._id !== id));
      addToast("Life experience deleted successfully", "success");
      return id;
    } catch (err) {
      setError(err.message);
      addToast(err.message || "Error deleting life experience", "error");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Load life experiences on mount
  useEffect(() => {
    fetchLifeExperiences();
  }, []);

  return {
    lifeExperiences,
    loading,
    error,
    fetchLifeExperiences,
    createLifeExperience,
    updateLifeExperience,
    deleteLifeExperience,
    setLifeExperiences,
  };
};
