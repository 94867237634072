import React, { useState, useEffect } from "react";
import StoryForm from "components/StoryForm/StoryForm";
import { useParams, useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import LoadingContainer from "components/LoadingContainer/LoadingContainer";
import LoadingMessage from "components/LoadingSpinner/LoadingMessage";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import * as API from "api/stories";

const EditStory = () => {
  const { storyId } = useParams();
  const { userData } = useUserContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [story, setStory] = useState(null);
  const [status, setStatus] = useState("idle");
  const [statusMessage, setStatusMessage] = useState("");

  // Load the story on component mount only (empty dependency array)
  useEffect(() => {
    const fetchStory = async () => {
      try {
        setLoading(true);
        const storyData = await API.fetchStoryById(storyId);

        if (storyData.author._id !== userData?._id) {
          throw new Error("You are not authorized to edit this story.");
        }

        setStory(storyData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching story:", err);
        setError(err.message || "Failed to load story");
        setLoading(false);
      }
    };

    fetchStory();
  }, [storyId, userData?._id]); // Only re-run if storyId or userData._id changes

  // Handle updating the story
  const updateStory = async (storyData) => {
    try {
      setStatus("submitting");
      setStatusMessage("Updating story...");
      await API.updateStory(storyId, storyData);
      setStatus("success");
      setStatusMessage("Story updated successfully!");
      navigate(`/stories/${storyId}`);
    } catch (err) {
      console.error("Error updating story:", err);
      setStatus("error");
      setStatusMessage("Error updating story. Please try again.");
    }
  };

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingMessage message="Loading story..." />
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <LoadingContainer>
        <ErrorMessage message={error} />
      </LoadingContainer>
    );
  }

  return (
    <StoryForm
      initialData={story}
      onSubmit={updateStory}
      status={status}
      statusMessage={statusMessage}
      theme="dark"
    />
  );
};

export default EditStory;
