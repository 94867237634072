// packages/client/src/components/Modal/Modal.jsx
import React, { useEffect } from "react";
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
} from "@floating-ui/react";
import styles from "./Modal.module.css";
import cn from "classnames";

// Note: Removed unused imports: ReactDOM, useRef, useState, Button, ModalButtons

const Modal = ({
  isOpen, // Directly use isOpen prop
  onClose,
  showCloseButton = true,
  children,
  // portalNodeSelector is no longer needed with FloatingPortal
  className,
}) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => !open && onClose(), // Call onClose when Floating UI requests close
  });

  // Interaction hooks
  // const click = useClick(context); // Optional: If clicking inside modal should do something specific
  const dismiss = useDismiss(context, { outsidePressEvent: "mousedown" }); // Close on outside click/escape
  const role = useRole(context); // Apply ARIA roles

  // Merge interactions
  const { getFloatingProps } = useInteractions([
    // click, // Add if needed
    dismiss,
    role,
  ]);

  // Prevent rendering if not open
  if (!isOpen) {
    return null;
  }

  return (
    <FloatingPortal>
      {" "}
      {/* Manages DOM portal insertion/removal */}
      <FloatingOverlay className={styles.modalOverlay} lockScroll>
        {" "}
        {/* Backdrop */}
        <FloatingFocusManager context={context} modal={true}>
          {" "}
          {/* Focus trapping */}
          <div
            ref={refs.setFloating}
            className={cn(styles.box, className)} // Apply box styles and custom className
            {...getFloatingProps()} // Apply interaction props
            // Prevent clicks inside the modal from closing it via the overlay
            onClick={(e) => e.stopPropagation()}
          >
            {showCloseButton && (
              <div className={styles["close-btn"]}>
                {/* Keep existing close button logic, calls the same onClose */}
                <button onClick={onClose} aria-label="Close modal">
                  &times;
                </button>
              </div>
            )}
            {children} {/* Render header, body, buttons etc. */}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default Modal;
